// ===============================================================
// ---------------------------------------------------------------
const Spinner = ({ optStyle }) => {
	return (
		// eslint-disable-next-line
		<div className='spinner' style={optStyle && optStyle}>
			<div className='lds-grid'>
				<div></div>
				<div></div>
				<div></div>
				<div></div>
				<div></div>
				<div></div>
				<div></div>
				<div></div>
				<div></div>
			</div>
		</div>
	)
}

export default Spinner
