const AboutQuery = `
{
  queryHomePageContents {
    id
    flatData {
      homeShowcase {
        id
        flatData {
          leftTextFirstRow
          leftTextSecondRow
          leftTextThirdRow
          showcaseText
          showcaseButtons {
            id
            flatData {
              isExternalLink
              buttonText
              buttonLink
              buttonBgColor {
                id
                flatData {
                  colorHex
                }
              }
              buttonTextColor {
                id
                flatData {
                  colorHex
                }
              }
              buttonBorderColor {
                id
                flatData {
                  colorHex
                }
              }
            }
          }
          showcaseRightImage {
            id
            metadata
          }
          showcaseColor {
            id
            flatData {
              colorHex
            }
          }
        }
      }
      mmaBasicsHeading
      mmaBasicsParagraph
      mmaBasicsIcons {
        id
        flatData {
          mmaBasicsIconImage {
            id
            metadata
          }
          mmaBasicsIconHeading
          mmaBasicsIconParagraph
        }
      }
      whoMmaHeading
      whoMmaParagraph
      whoMmaBgColor {
        id
        flatData {
          colorHex
        }
      }
      whoMmaIcons {
        id
        flatData {
          whoMmaIconImage {
            id
            metadata
          }
          whoMmaLayoutHeading
          whoMmaIconParagraph
          whoMmaButton {
            id
            flatData {
              isExternalLink
              buttonText
              buttonLink
              buttonBgColor {
                id
                flatData {
                  colorHex
                }
              }
              buttonTextColor {
                id
                flatData {
                  colorHex
                }
              }
              buttonBorderColor {
                id
                flatData {
                  colorHex
                }
              }
            }
          }
        }
      }
      largestRetailerHeading
      largestRetailerParagraph
      largestRetailerBrands {
        id
        flatData {
          brandLink
          brandImage {
            id
            metadata
          }
        }
      }
    }
  }
}

 `

export default AboutQuery
