const FourOhFourQuery = `
{
   query404PageContents {
     id
     flatData {
       xlHeading
       heading
       paragraph
       button {
         id
         flatData {
           isExternalLink
           buttonText
           buttonLink
           buttonBgColor {
             flatData {
               colorHex
             }
           }
           
           buttonTextColor {
             flatData {
               colorHex
             }
           }
           
           buttonBorderColor {
             flatData {
               colorHex
             }
           }
         }
       }
     }
   }
 }
 `
export default FourOhFourQuery
