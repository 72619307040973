// ===============================================================
// ---------------------------------------------------------------
// Dependencies
import { useEffect, useContext, Fragment, Suspense } from "react"

// Context
import AppContext from "../../Context/App/appContext"
import AppNavbar from "../Modules/AppNavbar"
import Footer from "../Modules/Footer"
import { Carousel } from 'react-responsive-carousel'
import 'react-responsive-carousel/lib/styles/carousel.min.css' // requires a loader
// Modules
import Spinner from "../Modules/Spinner"
import Theme from "../Modules/Theme"
import StuckBanking from "../Modules/StuckBanking"

// ===============================================================
const Banking = () => {
	// ---------------------------------------------------------------
	// Context
	const appContext = useContext(AppContext)
	const { getPeople, people } = appContext

	// ---------------------------------------------------------------
	// Get content and scroll to top on load
	useEffect(() => {
		getPeople()
		window.scrollTo(0, 0)
		// eslint-disable-next-line
	}, [])

	return (
		<Fragment>
			<Suspense fallback={<Spinner />}>
				{people ? (
					<Theme id="banking" className="banking">
						<AppNavbar />
						<Carousel autoPlay infiniteLoop showStatus={false} showIndicators={false} showThumbs={false} swipeable={false}>

							<div className='people-showcase' >
								<a href="https://www.termsconditions.co.za/content/dam/ShopriteGroup/Terms/PDFS/Money-Market-Account-Ts-Cs.pdf" target='_balnk'>
									<div>
									<picture >
										<source media="(min-width: 992px)" srcSet="https://cms.computicket.com/api/assets/mma/70de20a4-40de-41c1-a260-9e1ee5fe7c70/termsbannerd-min.png?version=0" />
										<source media="(min-width: 501px)" srcSet="https://cms.computicket.com/api/assets/mma/a2423f52-6fa3-44eb-b205-5b09a98b2551/termsbannert-min.png?version=0" />
										<img src="https://cms.computicket.com/api/assets/mma/53ea37a7-07af-4793-a69a-58fc25494543/termsbannerm-min.png?version=0" alt="IfItDoesntMatchAnyMedia" />
									</picture>
									</div>
								</a>
							</div>


						</Carousel>
						<StuckBanking />
						<Footer />
					</Theme>
				) : (
					<Spinner />
				)}
			</Suspense>
		</Fragment>
	)
}

export default Banking
