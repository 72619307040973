// ===============================================================
// ---------------------------------------------------------------
// Dependencies
import { useEffect, useContext, Fragment, Suspense, lazy } from "react"

// Context
import AppContext from "../../Context/App/appContext"
import AppNavbar from "../Modules/AppNavbar"

// Modules
import Spinner from "../Modules/Spinner"
import Theme from "../Modules/Theme"

// Lazy Modules
const BusinessPaymentShowcase = lazy(() => import("../Modules/BusinessPaymentShowcase"))
const BusinessPayoutSimplify = lazy(() => import("../Modules/BusinessPayoutSimplify"))
const StuckBusiness = lazy(() => import("../Modules/StuckBusiness"))
const BusinessPayoutGift = lazy(() => import("../Modules/BusinessPayoutGift"))
const BusinessPayoutPartners = lazy(() => import("../Modules/BusinessPayoutPartners"))


const Footer = lazy(() => import("../Modules/Footer"))

// ===============================================================
const ForBusiness = () => {
	// ---------------------------------------------------------------
	// Context
	const appContext = useContext(AppContext)
	const { business, getBusiness } = appContext

	// ---------------------------------------------------------------
	// Scroll to top on load and fetch content
	useEffect(() => {
		window.scrollTo(0, 0)
		getBusiness()
		// eslint-disable-next-line
	}, [])

	return (
		<Fragment>
			<Suspense fallback={<Spinner />}>
				{business ? (
					<Theme id="for-business" className="for-business">
						<AppNavbar />
						<BusinessPaymentShowcase />
						<BusinessPayoutSimplify/>
						<BusinessPayoutGift/>
						<BusinessPayoutPartners/>
						<StuckBusiness />
						<Footer />
					</Theme>
				) : (
					<Spinner />
				)}
			</Suspense>
		</Fragment>
	)
}

export default ForBusiness
