const AllFaqQuery = `
{
  queryFaqContents {
    id
    flatData {
      faqQuestion
      faqAnswer
    }
  }
}
 `
export default AllFaqQuery
