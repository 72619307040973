// ===============================================================
// ---------------------------------------------------------------
// Dependencies
import { useReducer } from "react"

// Context API
import RegisterContext from "./registerContext"
import RegisterReducer from "./registerReducer"
import {
	SET_CURRENT_STEP,
	SET_ACCOUNT_TYPE,
	SET_MOBILE_NUMBER,
	SET_ID_NUMBER,
	SET_PASSPORT_NUMBER,
	SET_SELECTED_ID_TYPE,
	SET_FIRST_NAME,
	SET_LAST_NAME,
	SET_EMAIL_ADDRESS,
	SET_PIN,
	SET_CONFIRM_PIN,
	SET_ACCEPT_DATA_PRIVACY,
	SET_READ_TCS,
	SET_SEND_UPDATES,
	SET_SMS_UPDATES,
	SET_WHATSAPP_UPDATES,
	SET_EMAIL_UPDATES,
	SET_OTP,
	SET_PROMO_CODE,
	SET_CARD_TYPE,
	SET_CARD_NUMBER,
	SET_SECURITY_QUESTION_1,
	SET_SECURITY_QUESTION_2,
	SET_SECURITY_QUESTION_3,
	SET_SECURITY_CORRECT_ANSWER_1,
	SET_SECURITY_CORRECT_ANSWER_2,
	SET_SECURITY_CORRECT_ANSWER_3,
	SET_SECURITY_SELECTED_ANSWER_1,
	SET_SECURITY_SELECTED_ANSWER_2,
	SET_SECURITY_SELECTED_ANSWER_3,
	SET_SECURITY_ANSWER_1_1,
	SET_SECURITY_ANSWER_1_2,
	SET_SECURITY_ANSWER_1_3,
	SET_SECURITY_ANSWER_2_1,
	SET_SECURITY_ANSWER_2_2,
	SET_SECURITY_ANSWER_2_3,
	SET_SECURITY_ANSWER_3_1,
	SET_SECURITY_ANSWER_3_2,
	SET_SECURITY_ANSWER_3_3,
	SET_RECAP,
	SET_ERROR_MOBILE_NUMBER,
	SET_ERROR_ID_NUMBER,
	SET_ERROR_PASSPORT_NUMBER,
	SET_ERROR_EMAIL_ADDRESS,
	SET_ERROR_FIRST_NAME,
	SET_ERROR_LAST_NAME,
	SET_ERROR_PIN,
	SET_ERROR_CONFIRM_PIN,
	SET_ERROR_OTP,
	SET_ERROR_CARD_NUMBER,
	SET_USER_ID,
	SET_TOKEN,
	SET_HAS_CARDS,
	SET_CARD_OTP,
	SET_CARD_OTP_IS_SENT,
	SET_DOB,
	SET_GENDER,
	SET_LOGIN_ERROR,
	SET_AUTO_GEN_PROMO_CODE,
} from "../types"

// Queries

// ---------------------------------------------------------------
// Initialize all global state
const RegisterState = (props) => {
	const initialState = {
		// apiProxy: process.env.REACT_APP_MMA_PROXY_DNS,
		apiProxy: "https://mmaproxy.computicket.com",
		// apiProxy: "https://pilot-mmaproxy.computicket.com",
		currentStep: "checkUser",
		accountType: "CASH",
		mobileNumber: null,
		idNumber: null,
		passportNumber: null,
		firstName: null,
		lastName: null,
		emailAddress: null,
		pin: null,
		confirmPin: null,
		acceptDataPrivacy: false,
		readTCs: false,
		sendUpdates: false,
		smsUpdates: false,
		whatsappUpdates: false,
		emailUpdates: false,
		otp: null,
		promoCode: null,
		selectedIdType: "ID",
		linkCard: null,
		cardType: "checkers",
		cardNumber: null,
		securityQuestion1: null,
		securityQuestion2: null,
		securityQuestion3: null,
		securityCorrectAnswer1: null,
		securityCorrectAnswer2: null,
		securityCorrectAnswer3: null,
		securitySelectedAnswer1: null,
		securitySelectedAnswer2: null,
		securitySelectedAnswer3: null,
		securityAnswer1_1: Object,
		securityAnswer1_2: Object,
		securityAnswer1_3: Object,
		securityAnswer2_1: Object,
		securityAnswer2_2: Object,
		securityAnswer2_3: Object,
		securityAnswer3_1: Object,
		securityAnswer3_2: Object,
		securityAnswer3_3: Object,
		recap: null,
		userId: null,
		errorMobileNumber: "Please have your phone ready to verify your number with an OTP",
		errorFirstName: null,
		errorLastName: null,
		errorIdNumber: null,
		errorPassportNumber: null,
		errorEmailAddress: null,
		errorPin: null,
		errorConfirmPin: null,
		errorOtp: null,
		errorCardNumber: null,
		token: null,
		hasCards: null,
		cardOtp: null,
		cardOtpIsSent: false,
		dob: null,
		gender: null,
		loginError: null,
		autoGenPromoCode: null,
	}

	const [state, dispatch] = useReducer(RegisterReducer, initialState)

	// ===============================================================
	// Define global functions

	const setCurrentStep = (step) => {
		dispatch({ type: SET_CURRENT_STEP, payload: step })
	}

	const setAccountType = (type) => {
		dispatch({ type: SET_ACCOUNT_TYPE, payload: type })
	}

	const setMobileNumber = (number) => {
		dispatch({ type: SET_MOBILE_NUMBER, payload: number })
	}

	const setIdNumber = (id) => {
		dispatch({ type: SET_ID_NUMBER, payload: id })
	}

	const setPassportNumber = (passport) => {
		dispatch({ type: SET_PASSPORT_NUMBER, payload: passport })
	}

	const setSelectedIdType = (type) => {
		dispatch({ type: SET_SELECTED_ID_TYPE, payload: type })
	}

	const setFirstName = (firstName) => {
		dispatch({ type: SET_FIRST_NAME, payload: firstName })
	}

	const setLastName = (lastName) => {
		dispatch({ type: SET_LAST_NAME, payload: lastName })
	}

	const setEmailAddress = (emailAddress) => {
		dispatch({ type: SET_EMAIL_ADDRESS, payload: emailAddress })
	}

	const setPin = (pin) => {
		dispatch({ type: SET_PIN, payload: pin })
	}

	const setConfirmPin = (confirmPin) => {
		dispatch({ type: SET_CONFIRM_PIN, payload: confirmPin })
	}

	const setAcceptDataPrivacy = (accept) => {
		dispatch({ type: SET_ACCEPT_DATA_PRIVACY, payload: accept })
	}

	const setReadTCs = (readTCs) => {
		dispatch({ type: SET_READ_TCS, payload: readTCs })
	}

	const setSendUpdates = (sendUpdates) => {
		dispatch({ type: SET_SEND_UPDATES, payload: sendUpdates })
	}

	const setSmsUpdates = (smsUpdates) => {
		dispatch({ type: SET_SMS_UPDATES, payload: smsUpdates })
	}

	const setWhatsappUpdates = (whatsappUpdates) => {
		dispatch({ type: SET_WHATSAPP_UPDATES, payload: whatsappUpdates })
	}

	const setEmailUpdates = (emailUpdates) => {
		dispatch({ type: SET_EMAIL_UPDATES, payload: emailUpdates })
	}

	const setOtp = (otp) => {
		dispatch({ type: SET_OTP, payload: otp })
	}

	const setPromoCode = (promoCode) => {
		dispatch({ type: SET_PROMO_CODE, payload: promoCode })
	}

	const setCardType = (cardType) => {
		dispatch({ type: SET_CARD_TYPE, payload: cardType })
	}

	const setCardNumber = (cardNumber) => {
		dispatch({ type: SET_CARD_NUMBER, payload: cardNumber })
	}

	const setSecurityQuestion1 = (q1) => {
		dispatch({ type: SET_SECURITY_QUESTION_1, payload: q1 })
	}

	const setSecurityQuestion2 = (q2) => {
		dispatch({ type: SET_SECURITY_QUESTION_2, payload: q2 })
	}

	const setSecurityQuestion3 = (q3) => {
		dispatch({ type: SET_SECURITY_QUESTION_3, payload: q3 })
	}

	const setSecurityCorrectAnswer1 = (c1) => {
		dispatch({ type: SET_SECURITY_CORRECT_ANSWER_1, payload: c1 })
	}

	const setSecurityCorrectAnswer2 = (c2) => {
		dispatch({ type: SET_SECURITY_CORRECT_ANSWER_2, payload: c2 })
	}

	const setSecurityCorrectAnswer3 = (c3) => {
		dispatch({ type: SET_SECURITY_CORRECT_ANSWER_3, payload: c3 })
	}

	const setSecuritySelectedAnswer1 = (s1) => {
		dispatch({ type: SET_SECURITY_SELECTED_ANSWER_1, payload: s1 })
	}

	const setSecuritySelectedAnswer2 = (s2) => {
		dispatch({ type: SET_SECURITY_SELECTED_ANSWER_2, payload: s2 })
	}

	const setSecuritySelectedAnswer3 = (s3) => {
		dispatch({ type: SET_SECURITY_SELECTED_ANSWER_3, payload: s3 })
	}

	const setSecurityAnswer1_1 = (a1_1) => {
		dispatch({ type: SET_SECURITY_ANSWER_1_1, payload: a1_1 })
	}

	const setSecurityAnswer1_2 = (a1_2) => {
		dispatch({ type: SET_SECURITY_ANSWER_1_2, payload: a1_2 })
	}

	const setSecurityAnswer1_3 = (a1_3) => {
		dispatch({ type: SET_SECURITY_ANSWER_1_3, payload: a1_3 })
	}

	const setSecurityAnswer2_1 = (a2_1) => {
		dispatch({ type: SET_SECURITY_ANSWER_2_1, payload: a2_1 })
	}

	const setSecurityAnswer2_2 = (a2_2) => {
		dispatch({ type: SET_SECURITY_ANSWER_2_2, payload: a2_2 })
	}

	const setSecurityAnswer2_3 = (a2_3) => {
		dispatch({ type: SET_SECURITY_ANSWER_2_3, payload: a2_3 })
	}

	const setSecurityAnswer3_1 = (a3_1) => {
		dispatch({ type: SET_SECURITY_ANSWER_3_1, payload: a3_1 })
	}

	const setSecurityAnswer3_2 = (a3_2) => {
		dispatch({ type: SET_SECURITY_ANSWER_3_2, payload: a3_2 })
	}

	const setSecurityAnswer3_3 = (a3_3) => {
		dispatch({ type: SET_SECURITY_ANSWER_3_3, payload: a3_3 })
	}

	const setRecap = (value) => {
		dispatch({ type: SET_RECAP, payload: value })
	}

	const setUserId = (uuid) => {
		dispatch({ type: SET_USER_ID, payload: uuid })
	}

	const setErrorMobileNumber = (errorMessage) => {
		dispatch({ type: SET_ERROR_MOBILE_NUMBER, payload: errorMessage })
	}

	const setErrorIdNumber = (errorMessage) => {
		dispatch({ type: SET_ERROR_ID_NUMBER, payload: errorMessage })
	}

	const setErrorPassportNumber = (errorMessage) => {
		dispatch({ type: SET_ERROR_PASSPORT_NUMBER, payload: errorMessage })
	}

	const setErrorEmailAddress = (errorMessage) => {
		dispatch({ type: SET_ERROR_EMAIL_ADDRESS, payload: errorMessage })
	}

	const setErrorFirstName = (errorMessage) => {
		dispatch({ type: SET_ERROR_FIRST_NAME, payload: errorMessage })
	}

	const setErrorLastName = (errorMessage) => {
		dispatch({ type: SET_ERROR_LAST_NAME, payload: errorMessage })
	}

	const setErrorPin = (errorMessage) => {
		dispatch({ type: SET_ERROR_PIN, payload: errorMessage })
	}

	const setErrorConfirmPin = (errorMessage) => {
		dispatch({ type: SET_ERROR_CONFIRM_PIN, payload: errorMessage })
	}

	const setErrorOtp = (errorMessage) => {
		dispatch({ type: SET_ERROR_OTP, payload: errorMessage })
	}

	const setErrorCardNumber = (errorMessage) => {
		dispatch({ type: SET_ERROR_CARD_NUMBER, payload: errorMessage })
	}

	const setToken = (token) => {
		dispatch({ type: SET_TOKEN, payload: token })
	}

	const setHasCards = (token) => {
		dispatch({ type: SET_HAS_CARDS, payload: token })
	}

	const setCardOtp = (otp) => {
		dispatch({ type: SET_CARD_OTP, payload: otp })
	}

	const setCardOtpIsSent = (input) => {
		dispatch({ type: SET_CARD_OTP_IS_SENT, payload: input })
	}

	const setDob = (idNumber) => {
		dispatch({ type: SET_DOB, payload: idNumber })
	}

	const setGender = (idNumber) => {
		dispatch({ type: SET_GENDER, payload: idNumber })
	}

	const setLoginError = (input) => {
		dispatch({ type: SET_LOGIN_ERROR, payload: input })
	}

	const setAutoGenPromoCode = (input) => {
		dispatch({ type: SET_AUTO_GEN_PROMO_CODE, payload: input })
	}

	// ===============================================================
	// Define state and functions to Context Provider
	return (
		<RegisterContext.Provider
			value={{
				apiProxy: state.apiProxy,
				currentStep: state.currentStep,
				accountType: state.accountType,
				mobileNumber: state.mobileNumber,
				idNumber: state.idNumber,
				passportNumber: state.passportNumber,
				firstName: state.firstName,
				lastName: state.lastName,
				emailAddress: state.emailAddress,
				pin: state.pin,
				confirmPin: state.confirmPin,
				acceptDataPrivacy: state.acceptDataPrivacy,
				readTCs: state.readTCs,
				sendUpdates: state.sendUpdates,
				smsUpdates: state.smsUpdates,
				whatsappUpdates: state.whatsappUpdates,
				emailUpdates: state.emailUpdates,
				otp: state.otp,
				promoCode: state.promoCode,
				selectedIdType: state.selectedIdType,
				linkCard: state.linkCard,
				cardType: state.cardType,
				cardNumber: state.cardNumber,
				errorMobileNumber: state.errorMobileNumber,
				errorIdNumber: state.errorIdNumber,
				errorPassportNumber: state.errorPassportNumber,
				errorFirstName: state.errorFirstName,
				errorLastName: state.errorLastName,
				errorEmailAddress: state.errorEmailAddress,
				errorPin: state.errorPin,
				errorConfirmPin: state.errorConfirmPin,
				errorOtp: state.errorOtp,
				errorCardNumber: state.errorCardNumber,
				securityQuestion1: state.securityQuestion1,
				securityQuestion2: state.securityQuestion2,
				securityQuestion3: state.securityQuestion3,
				securityCorrectAnswer1: state.securityCorrectAnswer1,
				securityCorrectAnswer2: state.securityCorrectAnswer2,
				securityCorrectAnswer3: state.securityCorrectAnswer3,
				securitySelectedAnswer1: state.securitySelectedAnswer1,
				securitySelectedAnswer2: state.securitySelectedAnswer2,
				securitySelectedAnswer3: state.securitySelectedAnswer3,
				securityAnswer1_1: state.securityAnswer1_1,
				securityAnswer1_2: state.securityAnswer1_2,
				securityAnswer1_3: state.securityAnswer1_3,
				securityAnswer2_1: state.securityAnswer2_1,
				securityAnswer2_2: state.securityAnswer2_2,
				securityAnswer2_3: state.securityAnswer2_3,
				securityAnswer3_1: state.securityAnswer3_1,
				securityAnswer3_2: state.securityAnswer3_2,
				securityAnswer3_3: state.securityAnswer3_3,
				recap: state.recap,
				userId: state.userId,
				token: state.token,
				hasCards: state.hasCards,
				cardOtp: state.cardOtp,
				cardOtpIsSent: state.cardOtpIsSent,
				dob: state.dob,
				gender: state.gender,
				loginError: state.loginError,
				autoGenPromoCode: state.autoGenPromoCode,
				setCurrentStep,
				setAccountType,
				setMobileNumber,
				setIdNumber,
				setPassportNumber,
				setSelectedIdType,
				setFirstName,
				setLastName,
				setEmailAddress,
				setPin,
				setConfirmPin,
				setAcceptDataPrivacy,
				setReadTCs,
				setSendUpdates,
				setSmsUpdates,
				setWhatsappUpdates,
				setEmailUpdates,
				setOtp,
				setPromoCode,
				setCardType,
				setCardNumber,
				setSecurityQuestion1,
				setSecurityQuestion2,
				setSecurityQuestion3,
				setSecurityCorrectAnswer1,
				setSecurityCorrectAnswer2,
				setSecurityCorrectAnswer3,
				setSecuritySelectedAnswer1,
				setSecuritySelectedAnswer2,
				setSecuritySelectedAnswer3,
				setSecurityAnswer1_1,
				setSecurityAnswer1_2,
				setSecurityAnswer1_3,
				setSecurityAnswer2_1,
				setSecurityAnswer2_2,
				setSecurityAnswer2_3,
				setSecurityAnswer3_1,
				setSecurityAnswer3_2,
				setSecurityAnswer3_3,
				setRecap,
				setErrorMobileNumber,
				setErrorIdNumber,
				setErrorPassportNumber,
				setErrorEmailAddress,
				setErrorFirstName,
				setErrorLastName,
				setErrorPin,
				setErrorConfirmPin,
				setErrorOtp,
				setErrorCardNumber,
				setUserId,
				setToken,
				setHasCards,
				setCardOtp,
				setCardOtpIsSent,
				setDob,
				setGender,
				setLoginError,
				setAutoGenPromoCode,
			}}
		>
			{props.children}
		</RegisterContext.Provider>
	)
}

export default RegisterState
