// ===============================================================
// ---------------------------------------------------------------
// Dependencies
import { useContext, useEffect, Fragment, Suspense, lazy } from "react"

// Context
import AppContext from "../../Context/App/appContext"
import AppNavbar from "../Modules/AppNavbar"

// Modules
import Spinner from "../Modules/Spinner"
import Theme from "../Modules/Theme"

// Lazy Modules
const FourOhFour = lazy(() => import("../Modules/FourOhFour"))
const StuckBusiness = lazy(() => import("../Modules/StuckBusiness"))
const Footer = lazy(() => import("../Modules/Footer"))

// ===============================================================
const NotFound = () => {
	// ---------------------------------------------------------------
	// Context
	const appContext = useContext(AppContext)
	const { getFourOhFour, fourOhFour } = appContext

	// ---------------------------------------------------------------
	// Scroll to top on load
	useEffect(() => {
		window.scrollTo(0, 0)
		getFourOhFour()
		// eslint-disable-next-line
	}, [])

	return (
		<Fragment>
			<Suspense fallback={<Spinner />}>
				{fourOhFour && (
					<Theme id="not-found" className="not-found">
						<AppNavbar />
						<FourOhFour />
						<StuckBusiness />
						<Footer />
					</Theme>
				)}
			</Suspense>
		</Fragment>
	)
}

export default NotFound
