// ===============================================================
// ---------------------------------------------------------------
// Dependencies
import { useEffect, Fragment, useContext, Suspense, lazy } from "react"

// Context
import AppContext from "../../Context/App/appContext"

// Modules
import Spinner from "../Modules/Spinner"
import FeesTabs from "../Modules/FeesTabs"
import Theme from "../Modules/Theme"
import AppNavbar from "../Modules/AppNavbar"

// Lazy Modules
const Footer = lazy(() => import("../Modules/Footer"))

// ===============================================================
const Fees = () => {
	// ---------------------------------------------------------------
	// Context
	const appContext = useContext(AppContext)
	const { fees, getFees } = appContext

	// ---------------------------------------------------------------
	// Scroll to top on load and get content
	useEffect(() => {
		window.scrollTo(0, 0)
		getFees()
		// eslint-disable-next-line
	}, [])

	return (
		<Fragment>
			<Suspense fallback={<Spinner />}>
				{fees ? (
					<Theme id={"fees"} className={"fees"}>
						<AppNavbar />
						<FeesTabs />
						<Footer />
					</Theme>
				) : (
					<Spinner />
				)}
			</Suspense>
		</Fragment>
	)
}

export default Fees
