// Dependencies
import { useEffect, Fragment, Suspense, lazy } from "react"
import AppNavbar from "../Modules/AppNavbar"

// Context
// import AppContext from '../../Context/App/appContext'

// Modules
import Spinner from "../Modules/Spinner"
import Theme from "../Modules/Theme"

// Lazy Modules
const Footer = lazy(() => import("../Modules/Footer"))
const RegEnd = lazy(() => import("../Modules/RegEnd.js"))

const RegisterEnd = () => {
	// Context
	// const appContext = useContext(AppContext)
	// const { getFaqs, getAllFaqs, faqs } = appContext

	// Scroll to top on load and get content
	useEffect(() => {
		window.scrollTo(0, 0)
		// eslint-disable-next-line
	}, [])

	return (
		<Fragment>
			<Suspense fallback={<Spinner />}>
				<Theme id="register-end" className="register-end">
					<AppNavbar />
					<RegEnd />
					<Footer />
				</Theme>
			</Suspense>
		</Fragment>
	)
}

export default RegisterEnd
