// ===============================================================
// ---------------------------------------------------------------
// Dependencies
import { Fragment } from 'react'
import PropTypes from 'prop-types'
import { HashLink as Link } from 'react-router-hash-link'

const Button = ({ button, optClass }) => {
	return (
		<Fragment>
			{button.flatData.isExternalLink ? (
				<a
					key={button.id}
					href={button.flatData.buttonLink}
					target='_blank'
					rel='noreferrer'
					className={`btn ${optClass ? optClass : ''}`}
					style={{
						backgroundColor: `${
							button.flatData.buttonBgColor ? button.flatData.buttonBgColor[0].flatData.colorHex : '#ffffff'
						}`,
						color: `${
							button.flatData.buttonTextColor ? button.flatData.buttonTextColor[0].flatData.colorHex : '#ffffff'
						}`,
						borderColor: `${
							button.flatData.buttonBorderColor
								? button.flatData.buttonBorderColor[0].flatData.colorHex
								: 'transparent'
						}`,
					}}
					onClick={button?.onPress}
				>
					<span className='no-break'>{button.flatData.buttonText}</span>
				</a>
			) : (
				<Link
					smooth
					key={button.id}
					to={button.flatData.buttonLink}
					className={`btn ${optClass ? optClass : ''}`}
					onClick={button?.onPress}
					style={{
						backgroundColor: `${
							button.flatData.buttonBgColor ? button.flatData.buttonBgColor[0].flatData.colorHex : '#ffffff'
						}`,
						color: `${
							button.flatData.buttonTextColor ? button.flatData.buttonTextColor[0].flatData.colorHex : '#ffffff'
						}`,
						borderColor: `${
							button.flatData.buttonBorderColor
								? button.flatData.buttonBorderColor[0].flatData.colorHex
								: 'transparent'
						}`,
					}}
				>
					<span className='no-break'>{button.flatData.buttonText}</span>
				</Link>
			)}
		</Fragment>
	)
}

Button.propTypes = {
	button: PropTypes.object.isRequired,
}

export default Button
