import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'

const FeesLimits = () => {
	return (
		<>
			<div className='fees-limits' id='fees-limts'>
				<div className='limitsHeaderContainer'>
					<h2>Limits</h2>
					<p>Sadly, everything has limits.</p>
				</div>
				<Tabs>
					<TabList>
						<div className='limit-tabs-selectors'>
							<Tab className={`limit-tabs-selector limit-tabs-selector-recipients`} key="0">
								<h6> Cash Account </h6>
							</Tab>
							<Tab className={`limit-tabs-selector limit-tabs-selector-business`} key="1">
								<h6> Voucher Account </h6>
							</Tab>
						</div>
					</TabList>

					<TabPanel key="0">
						<div className='limits-tabs-content'>
							<ul>
								<li >
									<div className='limitItemHeader'> Maximum account balance </div>
									<div className='limitItemDescription'> R25 000 </div>
								</li>
								<li >
									<div className='limitItemHeader'>EFT in</div>
									<div className='limitItemDescription'> R1 - R10 000 </div>
								</li>
								<li >
									<div className='limitItemHeader'>Send money</div>
									<div className='limitItemDescription'> R5 - R5 000 </div>
								</li>
								<li >
									<div className='limitItemHeader'>Cash deposit in-store</div>
									<div className='limitItemDescription'> R0.1 - R5 000 </div>
								</li>
								<li >
									<div className='limitItemHeader'>Cash withdrawal in-store</div>
									<div className='limitItemDescription'> R10 - R5 000 </div>
								</li>
								<li >
									<div className='limitItemHeader'>Prepaid</div>
									<div className='limitItemDescription'> Hollywood Bets R10 to R1000 </div>
									<div className='limitItemDescription'> Airtime/Data R2 - R1000 </div>
									<div className='limitItemDescription'> Electricity R10 - R1000 </div>

								</li>
								<li >
									<div className='limitItemHeader'>Pay my accounts</div>
									<div className='limitItemDescription'> The minimum and maximum limits are dependant on 3rd party billers, you will receive a notification if limits are reached during the purchasing process.</div>
								</li>
	
							</ul>
						</div>
					</TabPanel>
					<TabPanel key="1">
						<div className='limits-tabs-content'>
						<ul>
								<li >
									<div className='limitItemHeader'> Maximum account balance </div>
									<div className='limitItemDescription'> R1 300 000 </div>
								</li>
								<li >
									<div className='limitItemHeader'>Monthly account movement</div>
									<div className='limitItemDescription'> R3 000 000 </div>
								</li>
								<li >
									<div className='limitItemHeader'>OZOW EFT in</div>
									<div className='limitItemDescription'> R10 - R3 000 </div>
								</li>
								<li >
									<div className='limitItemHeader'>Cash deposit in-store</div>
									<div className='limitItemDescription'> R0.1 - R5 000 </div>
								</li>
								<li >
									<div className='limitItemHeader'>Send money</div>
									<div className='limitItemDescription'> R5 - R5 000 </div>
								</li>
								<li >
									<div className='limitItemHeader'>Prepaid</div>
									<div className='limitItemDescription'> Hollywood Bets R10 to R1000</div>
									<div className='limitItemDescription'> Airtime/Data R2 - R1000 </div>
									<div className='limitItemDescription'> Electricity R10 - R1000 </div>

								</li>
								<li >
									<div className='limitItemHeader'>Pay my accounts</div>
									<div className='limitItemDescription'> The minimum and maximum limits are dependant on 3rd party billers, you will receive a notification if limits are reached during the purchasing process.</div>
								</li>
							</ul>
						</div>
					</TabPanel>

				</Tabs>
			</div>
		</>
	)
}

export default FeesLimits
