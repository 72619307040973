// ===============================================================
// ---------------------------------------------------------------
// Dependencies
import { useContext, useEffect, Fragment } from 'react'

// Modules
import Button from './Button'

// Context
import AppContext from '../../Context/App/appContext'

// ===============================================================
const StuckBusiness = () => {
	// ---------------------------------------------------------------
	// Context
	const appContext = useContext(AppContext)
	const { image_url, getStuckBusiness, stuckBusiness, cellSplit } = appContext

	// ---------------------------------------------------------------
	// Get content
	useEffect(() => {
		getStuckBusiness()
		// eslint-disable-next-line
	}, [])

	// ---------------------------------------------------------------
	// Destructured Variables
	const {
		leftColumnHeading,
		leftColumnText,
		leftColumnImage,
		leftColumnButton,
		rightColumnHeading,
		rightColumnText,
		rightColumnEmail,
		rightColumnPhone,
		rightColumnImage,
		phoneIcon,
		emailIcon,
	} = stuckBusiness

	// ===============================================================
	return (
		<Fragment>
			{stuckBusiness && (
				<div className='stuck' id='stuck'>
					<div className='stuck-container'>
						<div className='boxes'>
							<div className='box'>
								<img src={`${image_url}${leftColumnImage[0].id}`} alt={leftColumnImage[0].metadata.alt} />
								<h2> {leftColumnHeading} </h2>
								<p> {leftColumnText} </p>
								<Button className='test123' button={leftColumnButton[0]} />
							</div>
							<div className='box'>
								<img src={`${image_url}${rightColumnImage[0].id}`} alt={rightColumnImage[0].metadata.alt} />
								<h2> {rightColumnHeading} </h2>
								<p> {rightColumnText} </p>
								<div className='contacts' >
									<a className='contact-a' href={`tel:+27860010709`} style={{justifyContent: 'center'}}>
										<img src={`${image_url}${phoneIcon[0].id}`} alt={phoneIcon[0].metadata.alt} />
										0860 010 709
									</a>
									<a className='contact-a underline' href={`mailto:${rightColumnEmail}`} style={{justifyContent: 'center'}}>
										<img src={`${image_url}${emailIcon[0].id}`} alt={emailIcon[0].metadata.alt} />
										{rightColumnEmail}
									</a>
								</div>
							</div>
						</div>
					</div>
				</div>
			)}
		</Fragment>
	)
}

export default StuckBusiness
