// ===============================================================
// ---------------------------------------------------------------
// Dependencies
import React, { useContext, Fragment } from 'react'
import Select from 'react-select'

// Context
import AppContext from '../../Context/App/appContext'

// ===============================================================
const SwitchHeader = () => {
	// ---------------------------------------------------------------
	// Context
	const appContext = useContext(AppContext)
	const { image_url, business } = appContext

	// Destructured Variables
	const { howMmaHeading, howMmaParagraph, howMmaIcons, howMmaSelectOptions } = business

	// ---------------------------------------------------------------
	// Options for select dropdown
	const options = []
	howMmaSelectOptions.forEach((option) => {
		options.push(option.flatData)
	})

	return (
		<Fragment>
			{business && (
				<div className='how-mma-business' id='how-mma-business'>
					<div className='bg-top'></div>
					<h2 id='switchHeader' className='switchHeader'> Switch your salary or SASSA grant to SA’s lowest cost bank account </h2>
					<Select className='custom-select' options={options} />
					{/* <p className='how-mma-business-lead'><b>Plus</b> get up to <b>R100 VOUCHERS*</b> when your first salary or SASSA grant is paid into your Money Market Account!</p> */}
					<p className='how-mma-business-lead'>Get <b>full control over your money</b> through SMS payment notifications and real-time balance enquiries on your phone.</p>

					{/* <div style={{marginTop: '30px'}}></div>
					<h2 className='switchIsEasyHeader'> Switching is easy </h2>
					<div className='switchEasyContainer'>
						<div className='switchEasyLeftContainer'>
							<div className='leftSwitchHeader'><span>Switch your salary</span></div>
							<div className='switchStepsContainer'>
								<div className='switchStep'>
									<img src='https://cms.computicket.com/api/assets/mma/b17494e9-cc17-4a49-8476-2e56e1dec2ca/red1.png?version=0' />

									<div className='switchStepText'>
										<div><b>REGISTER:</b></div>
										<div >
											Dial <b>*120*3534#</b> OR WhatsApp <b>087 240 5709</b> or
											in-store at Money Market OR <b>download</b> the Shoprite app
										</div>
									</div>
								</div>
								<div className='switchStep'>
									<img src='https://cms.computicket.com/api/assets/mma/ce083d7b-752f-4e0d-9f0d-386ad10e2b9d/red2.png?version=0' />
									<div className='switchStepText'>
										<div >
											<b>Login</b> &amp; download <b>Bank Confirmation Letter</b>
										</div>
									</div>
								</div>
								<div className='switchStep'>
									<img src='https://cms.computicket.com/api/assets/mma/a94ce49e-d93c-4efb-8ad5-ed970c90d2f6/red3.png?version=0' />
									<div className='switchStepText'>
										<div >
											<b>Share</b> your letter with HR
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className='switcCeneteContainer'>
							<div class="switchVl"></div>
							
						</div>
						<hr className='hLine'></hr>
						<div className='switchEasyRightContainer'>
							<div className='rightSwitchHeader'><span>Switch your SASSA</span></div>
							<div className='switchStepsContainer'>
								<div className='switchStep'>
									<img src='https://cms.computicket.com/api/assets/mma/4974571f-54bf-49d5-bc35-48f17cebff64/green1.png?version=0' />

									<div className='switchStepText'>
										<div><b>REGISTER:</b></div>
										<div >
											Dial <b>*120*3534#</b> OR WhatsApp <b>087 240 5709</b> or
											in-store at Money Market OR <b>download</b> the Shoprite app
										</div>
									</div>
								</div>
								<div className='switchStep'>
									<img src='https://cms.computicket.com/api/assets/mma/191bf3d8-f88a-4de3-9751-df7d172bf1e1/green2.png?version=0' />
									<div className='switchStepText'>
										<div >
											<b>Login</b> &amp; download <b>Bank Confirmation Letter</b>
										</div>
									</div>
								</div>
								<div className='switchStep'>
									<img src='https://cms.computicket.com/api/assets/mma/9ff3f26e-c651-4de8-bb19-e63142f378b9/green3.png?version=0' />
									<div className='switchStepText'>
										<div >
										Download the <b>SASSA</b> form <a style={{borderBottom: '1px solid #aaa'}} target="_blank" rel='noreferrer' href='https://cms.computicket.com/api/assets/mma/4a7088ae-e7c2-4b08-a1e2-d9eecf558f5e/consent-form-for-bank-payment-online.pdf?version=0' download="consent-form-for-bank-payment-online">here</a>
										</div>
									</div>
								</div>
								<div className='switchStep'>
									<img src='https://cms.computicket.com/api/assets/mma/29b7f8f1-5dbf-4f04-830b-4b6c8f5d0e3f/green4.png?version=0' />
									<div className='switchStepText'>
										<div >
										Take your printed <b>Bank Confirmation Letter</b>, completed <b>SASSA</b> switch form and original ID to your nearest <b>SASSA office</b>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div> */}

				</div>

			)
			}

		</Fragment >
	)
}

export default SwitchHeader
