const FooterQuery = `
{
   queryFooterContents {
     id
     flatData {
       footerColDownloadHeading
       footerColDownloadItems {
         id
         flatData {
           link
           image {
             id
             metadata
           }
         }
       }
       footerColCompanyHeading
       footerColCompanyItems {
         id
         flatData {
           isExternal
           text
           link
         }
       }
       footerColShopriteHeading
       footerColShopriteItems {
         id
         flatData {
           text
           link
           image {
             id
             metadata
           }
         }
       }
       footerColCheckersHeading
       footerColCheckersItems {
         id
         flatData {
           text
           link
           image {
             id
             metadata
           }
         }
       }
       footerColOtherHeading
       footerColOtherItems {
         id
         flatData {
           isExternal
           text
           link
         }
       }
       footerBrands {
         id
         flatData {
           brandLink
           brandImage {
             id
             metadata
           }
         }
       }
       footerPolicyLinks {
         id
         flatData {
           text
           link
         }
       }
       footerCopyright
     }
   }
 }
 
 `
export default FooterQuery
