const FeesQuery = `
{
   queryFeesContents {
     id
     flatData {
       feesTabs {
         id
         flatData {
           stuckComponent
           className
           tabName
           tabHeading
           tabText
           tabListItems {
             id
             flatData {
               leftText
               rightText
             }
           }
         }
       }
     }
   }
 }
 `
export default FeesQuery
