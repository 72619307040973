const FaqQuery = `
{
   queryFaqCategoriesContents {
     id
     flatData {
       faqCategoryName
       faqCategoryContents {
         id
         flatData {
           faqGroupHeading
           faqGroupContents {
             id
             flatData {
               faqQuestion
               faqAnswer
             }
           }
         }
       }
     }
   }
 }
 `

export default FaqQuery
