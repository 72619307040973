import React from 'react';
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
    AccordionItemState,
} from 'react-accessible-accordion';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';

const faqData = [
    {
        id: 1,
        question: "Why is MMA enhancing onboarding?",
        answer: "It is a Regulatory Requirement: Enhanced onboarding ensures all the required information is gathered. It also reduces the risk of non-compliance and subsequent penalties."
    },
    {
        id: 2,
        question: "How does this customer onboarding help reduce fraud risk?",
        answer: "A well-executed customer onboarding process:<ul><li>Deters fraudsters by implementing robust identity verification measures.</li><li>Helps detect suspicious activity before it negatively impacts our business.</li></ul>"
    },
    {
        id: 3,
        question: "What impact does onboarding have on customer loyalty?",
        answer: "A well-executed onboarding process:<ul><li>Improves customer experience by providing a sense of additional safety and security around their account.</li><li>Enhances customer loyalty by establishing trust from the outset.</li></ul>"
    },
    {
        id: 4,
        question: "How does a FICA-compliant customer base benefit MMA?",
        answer: "A FICA-compliant base allows us to:<ul><li>Offer additional features and functions to our customers.</li><li>Rethink account limits based on enhanced customer profiles.</li></ul>"
    },
    {
        id: 5,
        question: "Can a customer still register using the USSD?",
        answer: "No, with the enhanced onboarding process, you will no longer be able to use USSD for registration. However, you can still register using the app, and in the future, registration via web, MMA counters, and WhatsApp will also be available."
    },
    {
        id: 6,
        question: "What information will be required from the customer?",
        answer: "KYC information: SA ID, physical identification, source of funds, occupation, home address."
    },
    {
        id: 7,
        question: "Do all customers need to submit all 5 sources of information?",
        answer: "No, the sources of information required vary based on the risk profile of the customer. However, all customers will still be required to provide a selfie and a picture of their ID."
    },
    {
        id: 8,
        question: "What if the customer selects the incorrect type of identification?",
        answer: "To be determined"
    },
    {
        id: 9,
        question: "What if the customer only has a feature phone?",
        answer: "Existing customers can still transact until they are required to re-onboard and thereafter, they must re-onboard either at the Kiosk or with a Yes-Learner. New customers can only be assisted at the MMA Counter or by a Yes-learner to register."
    }
];

const OnboardingFAQ = () => {
    return (
        <div className='faq-tabs faq-tabs-xtra' id='faq-tabs'>
            <div className='faq-tabs-container'>
                <h2 className='xtra-questions-header'>Frequently Asked Questions</h2>
                <p className='xtra-questions-sub-header'>Stuck and need help? Our handy FAQ section may be able to help!</p>

                <Accordion allowMultipleExpanded allowZeroExpanded>
                    {faqData.map((faq) => (
                        <AccordionItem
                            className='faq-tabs-sub'
                            key={faq.id}
                            id={`accordion-${faq.id}`}
                            uuid={`accordion-${faq.id}`}
                        >
                            <AccordionItemHeading>
                                <AccordionItemButton className='faq-tabs-sub-heading'>
                                    {faq.question}
                                    <AccordionItemState>
                                        {({ expanded }) =>
                                            expanded ? (
                                                <FaChevronUp size='20px' />
                                            ) : (
                                                <FaChevronDown size='20px' />
                                            )
                                        }
                                    </AccordionItemState>
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel
                                className='faq-tabs-sub-content faqAnswers'
                                dangerouslySetInnerHTML={{ __html: faq.answer }}
                            />
                        </AccordionItem>
                    ))}
                </Accordion>
            </div>
        </div>
    );
};

export default OnboardingFAQ;