import React from 'react';
import './OnboardingKycFica.css';

const OnboardingKycFica = () => {
    const downloadImage = (url, filename) => {
        fetch(url)
            .then(response => response.blob())
            .then(blob => {
                const link = document.createElement('a');
                link.href = URL.createObjectURL(blob);
                link.download = filename;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            })
            .catch(error => console.error('Error downloading image:', error));
    };

    return (
        <div className="onboarding-kyc-fica">
            <section className="info-section">
                <div className="icon-container">
                    <img src="https://cms.computicket.com/api/assets/mma/b12ca349-b0ff-449a-8cfe-422531b0a8a5/kyc1.png?version=0" alt="KYC/FICA icon" />
                </div>
                <div className="content">
                    <h2>What is KYC/FICA?</h2>
                    <p>KYC stands for Know Your Customer, and FICA stands for Financial Intelligence Centre Act. These are regulations that banks and other financial institutions need to follow to prevent fraud, money laundering, and terrorism financing. In simpler terms, it means that we need to verify your identity and where your money comes from to ensure that everything is legal and safe.</p>
                </div>
            </section>

            <section className="info-section reverse">
                <div className="icon-container">
                    <img src="https://cms.computicket.com/api/assets/mma/e63f63c1-aeb9-4304-abd6-fd2378dd47b2/kyc2.png?version=0" alt="Protection icon" />
                </div>
                <div className="content">
                    <h2>How does it protect me?</h2>
                    <p>You need to complete KYC/FICA so that we can confirm your identity and make sure that you are who you say you are. It is for your own protection, as it helps to prevent fraudsters from accessing your account and stealing your money. By completing KYC/FICA, we can also keep track of your transactions and notify you if we notice any unusual activity on your account.</p>
                </div>
            </section>

            <section className="info-section">
                <div className="icon-container">
                    <img src="https://cms.computicket.com/api/assets/mma/22c6bf84-7348-45b8-9dfa-59c0b46b7d93/kyc3.png?version=0" alt="Frequency icon" />
                </div>
                <div className="content">
                    <h2>How often do I need to FICA?</h2>
                    <p>You need to complete KYC/FICA once when you open an account with us. After that, we may need to verify your ID again if there are certain changes to your account or if we suspect fraud. Don't worry, we'll let you know if anything needs to be updated.</p>
                </div>
            </section>

            <section className="info-section reverse">
                <div className="icon-container">
                    <img src="https://cms.computicket.com/api/assets/mma/de2a788d-f5cc-48dd-a061-6a7988f00d9e/kyc4.png?version=0" alt="Documents icon" />
                </div>
                <div className="content">
                    <h2>What documents do I need to FICA?</h2>
                    <ul>
                        <li><strong>Original latest ID:</strong><br /> Smartcard or green ID book</li>
                        <br />
                        <li><strong>Source of funds:</strong><br /> All we need to know is where the money in your account comes from, such as your salary, investments, or inheritance, etc.</li>
                        <br />
                        <li><strong>Occupation:</strong><br /> This refers to the type of work or job that you do to earn a living.</li>
                        <br />
                        <li><strong>Home address</strong><br />Where you live.</li>
                    </ul>
                </div>
            </section>

            <section className="video-section">
                <div className="video-section-header">
                    <h2>How to video</h2>
                </div>
                <div className="video-container">
                    <iframe
                        width="560"
                        height="315"
                        src="https://www.youtube.com/embed/FnmgH8hZWvY"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                    ></iframe>
                </div>
            </section>

            <section className="tips-section">
                <h2>Tips</h2>
                <p className="tips-text">
                    Make sure your ID and proof of residence are up to date and match the information you provided when you opened your account. Keep a digital copy of your ID and proof of residence saved somewhere safe, in case you need it again in the future. If you are unsure about anything, please don't hesitate to contact us for assistance.
                </p>

                <div className="card-container">
                    <div className="card-link" onClick={() => downloadImage('https://cms.computicket.com/api/assets/mma/64d4120e-5a1c-4554-b969-29780292f9c0/d1-min.png?version=0', 'ready-to-open-account.png')}>
                        <img src="https://cms.computicket.com/api/assets/mma/25f98336-ae24-41c4-8770-bc1f1de88a73/t1-min.png?version=0" alt="Ready to open a bank account?" className="card-image" />
                    </div>
                    <div className="card-link" onClick={() => downloadImage('https://cms.computicket.com/api/assets/mma/755afdd2-f2ad-4768-a2d7-b527a5f27633/d2-min.png?version=0', 'setting-up-pin.png')}>
                        <img src="https://cms.computicket.com/api/assets/mma/0b7e5ab0-5bfb-49e2-889f-53660b7b3773/t2-min.png?version=0" alt="Setting up your 5-digit secret PIN" className="card-image" />
                    </div>
                    <div className="card-link" onClick={() => downloadImage('https://cms.computicket.com/api/assets/mma/e4256e32-fe40-45e9-b271-e1df3b1716d0/d3-min.png?version=0', 'take-good-selfie.png')}>
                        <img src="https://cms.computicket.com/api/assets/mma/5ee923ba-045b-4a7d-8431-17907b49f636/t3-min.png?version=0" alt="How to take a good selfie" className="card-image" />
                    </div>
                    <div className="card-link" onClick={() => downloadImage('https://cms.computicket.com/api/assets/mma/1c490dfe-143b-45fc-918d-0144e3f7e97b/d4-min.png?version=0', 'uploading-id-documents.png')}>
                        <img src="https://cms.computicket.com/api/assets/mma/1fd75457-66f5-483d-9713-4c02a1aec735/t4-min.png?version=0" alt="Uploading your ID documents" className="card-image" />
                    </div>
                </div>
            </section>
        </div>
    );
};

export default OnboardingKycFica;