const NavbarQuery = `
{
   queryNavbarContents {
     id
     flatData {
       navbarLogo {
         id
         metadata
       }
       navbarButton {
         id
         flatData {
           isExternalLink
           buttonText
           buttonLink
           buttonBgColor {
             id
             flatData {
               colorHex
             }
           }
           buttonTextColor {
             id
             flatData {
               colorHex
             }
           }
           buttonBorderColor {
             id
             flatData {
               colorHex
             }
           }
         }
       }
       navbarSubText 
       navbarSubLogo {
         id
         metadata
       }
     }
   }
 }
 
 
 `
export default NavbarQuery
