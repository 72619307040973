// Dependencies
import { useEffect, Fragment, Suspense, lazy } from "react"
import AppNavbar from "../Modules/AppNavbar"
// import InviteFriends from "../Modules/inviteFriends"
import InviteFriendsDisabled from "../Modules/inviteFriendsDisabled"

// import PromoWidget from "../Modules/PromoWidget"

// Context
// import AppContext from '../../Context/App/appContext'


// Modules
import Spinner from "../Modules/Spinner"
import Theme from "../Modules/Theme"

// Lazy Modules
const Footer = lazy(() => import("../Modules/Footer"))

const Share = () => {
	// Context
	// const appContext = useContext(AppContext)
	// const { getFaqs, getAllFaqs, faqs } = appContext

	// Scroll to top on load and get content
	useEffect(() => {
		window.scrollTo(0, 0)
		// eslint-disable-next-line
	}, [])

	return (
		<Fragment>
			<Suspense fallback={<Spinner />}>
				<Theme id="share" className="share">
					<AppNavbar />
					<InviteFriendsDisabled />
					{/* <PromoWidget /> */}
					<div className="freeDataDivContainer">
						<a href="https://free.moneymarketaccount.co.za/" target="_blank" rel="noreferrer">
							<img src="https://cms.computicket.com/api/assets/mma/4da91c63-f4ab-429f-9715-d21d140c5aac/100mbfreeimage.png?version=0" alt="100mb free"/>
						</a>
					</div>
					<a
						className="referraltcs"
						href="https://cms.computicket.com/api/assets/mma/de1de085-0110-4010-93b4-2b4a1bb49df9/money-market-account-share-and-earn-referral-rules-aa-comments-and-tracked-changes-fa-16052022.pdf?version=0"
					>
						Referral terms and conditions
					</a>
					
					<Footer />
				</Theme>
			</Suspense>
		</Fragment>
	)
}

export default Share
