// Dependencies
import React, { useContext, Fragment } from 'react'
import { FaChevronDown, FaChevronUp } from 'react-icons/fa'
import {
	Accordion,
	AccordionItem,
	AccordionItemHeading,
	AccordionItemButton,
	AccordionItemPanel,
	AccordionItemState,
} from 'react-accessible-accordion'

// Context
import AppContext from '../../Context/App/appContext'

function GetStartedSteps() {
	// Context
	const appContext = useContext(AppContext)
	const { image_url, business, cellSplit } = appContext

	// Destructuring Variables
	const { businessStepsHeading, businessStepsListItems } = business
	const { colorHex: bgColor } = business.businessStepsBgColor[0].flatData

	return (
		<Fragment>
			{business && (
				<div className='business-steps business-steps-whatsapp' id='business-steps' style={{ backgroundColor: `${bgColor}` }}>
					<div id="howTomain" className='bg-top howTomainWhatsappSteps' style={{ backgroundColor: `${bgColor}` }}></div>
					<div  className='business-steps-container business-steps-container-whatsapp'>
						<h2>Get started in 3 easy steps</h2>
						<div className='stepsPhoneContainer'>
							<div className='stepsPhoneItemContainerWhatsapp'>
								<div className='stepsPhoneHeader'>
									<img  className='whatsAppstepsIcon'  src='https://cms.computicket.com/api/assets/mma/9ba0e4ae-81cc-4d14-901a-cbc63c4c2d32/ws1.png?version=0'></img>
									<div className='whatsAppstepsPhoneHeaderText'><b>Sign in or Register a <br />Money Market Account </b></div>
									<div className='whatsAppstepsPhoneHeaderText'>WhatsApp the letters<br />
										<b>“MMA”</b> to 087 240 5385<br />
										Or click <a target="_blank" href='https://api.whatsapp.com/send/?phone=27872405385&text=MMA&type=phone_number&app_absent=0' rel="noreferrer"><u><b>here</b></u></a></div>

								</div>
								<div className='stepsPhoneImageWhatsapp'>
									<img src='https://cms.computicket.com/api/assets/mma/72453868-b1d9-4d47-8eb6-5b0dd0f2ec1d/wp1.png?version=0'></img>

								</div>

							</div>
							<div className='stepsPhoneItemContainerWhatsapp'>
								<div className='stepsPhoneHeader'>
									<img className='whatsAppstepsIcon' src='https://cms.computicket.com/api/assets/mma/4051bbb9-428d-42da-8339-c581f7adce26/ws2.png?version=0'></img>
									<div className='whatsAppstepsPhoneHeaderText'><b>Deposit money <br />into your account</b></div>
									<div className='whatsAppstepsPhoneHeaderText'>At any till using your<br /> <b>phone</b> or linked<br /><b>Xtra Savings card</b></div>

								</div>
								<div className='stepsPhoneImageWhatsapp'>
									<img src='https://cms.computicket.com/api/assets/mma/56af8b83-c988-4657-9921-18cac3455707/wp2.png?version=0'></img>

								</div>

							</div>
							<div className='stepsPhoneItemContainerWhatsapp'>
								<div className='stepsPhoneHeader'>
									<img className='whatsAppstepsIcon' src='https://cms.computicket.com/api/assets/mma/f3ad223a-844e-4f42-b9af-5647bab599cc/ws3.png?version=0'></img>
									<div className='whatsAppstepsPhoneHeaderText'><b>Pay your bills</b><br />&nbsp;</div>
									<div className='whatsAppstepsPhoneHeaderText'>Sign in to your<br/>account, select<br/><b>“PAY MY ACCOUNTS“</b></div>

								</div>
								<div className='stepsPhoneImageWhatsapp'>
									<img src='https://cms.computicket.com/api/assets/mma/99dacc17-1b72-4d1f-bb50-498642a57426/wp3.png?version=0'></img>

								</div>

							</div>
						</div>



					</div>
					<div className='bg-bot' style={{ backgroundColor: `${bgColor}` }}></div>
				</div>
			)}
		</Fragment>
	)
}

export default GetStartedSteps
