// ===============================================================
// ---------------------------------------------------------------
// Dependencies
import { useEffect, useContext, Fragment, Suspense, lazy } from "react"

// Context
import AppContext from "../../Context/App/appContext"
import AppNavbar from "../Modules/AppNavbar"

// Modules
import Spinner from "../Modules/Spinner"
import Theme from "../Modules/Theme"

// Lazy Modules
const CashbackShowcase = lazy(() => import("../Modules/CashbackShowcase"))
const CashbackInfoNew = lazy(() => import("../Modules/CashbackInfoNew"))

const CashbackInfo2 = lazy(() => import("../Modules/CashbackInfo2"))

const CashBackFAQ = lazy(() => import("../Modules/CashBackFAQ "))

const Footer = lazy(() => import("../Modules/Footer"))


// ===============================================================
const CashBack = () => {
	// ---------------------------------------------------------------
	// Context
	const appContext = useContext(AppContext)
	const { getPeople, people,getFaqs, getAllFaqs } = appContext
	// ---------------------------------------------------------------
	// Get content and scroll to top on load
	useEffect(() => {
		getPeople()
		window.scrollTo(0, 0);
		getFaqs()
		getAllFaqs()
		// eslint-disable-next-line
	}, [])



	return (
		<Fragment>
			<Suspense fallback={<Spinner />}>
				{people ? (
					<Theme id="link-card" className="link-card">
						<AppNavbar />
						<CashbackShowcase />
						<CashbackInfoNew />
						<CashbackInfo2/>
						<CashBackFAQ/>

						<Footer />
					</Theme>
				) : (
					<Spinner />
				)}
			</Suspense>
		</Fragment>
	)
}

export default CashBack
