import React from "react"

const InviteFriends = () => {
	return (
		<div className="invite-friends" id="invite-friends">
			<div className="invite-friends-container">
				'<h1>Share & Earn <br/>has come to an end.</h1>
				<p>
					Watch this space for upcoming
					promos and competitions.
				</p>
				<div className="icons">
					<img
						src="https://cms.computicket.com/api/assets/mma/c69a962c-e8e6-486e-ad19-ce9517232dfc/frame.png?version=0" alt="group"/>
				</div>
			</div>
		</div>
	)
}

export default InviteFriends
