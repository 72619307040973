// ===============================================================
// ---------------------------------------------------------------
// Dependencies
import { useContext } from 'react'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'

// Modules
import StuckBusiness from '../Modules/StuckBusiness'
import StuckPeople from '../Modules/StuckPeople'
import FeesLimits from '../Modules/FeesLimits'

// Context
import AppContext from '../../Context/App/appContext'

const FeesTabs = () => {
	// ---------------------------------------------------------------
	// Context
	const appContext = useContext(AppContext)
	const { fees } = appContext

	// ---------------------------------------------------------------
	// Destructured Variables
	const { feesTabs } = fees

	return (
		<>
			<Tabs>
				<TabList>
					<div className='fees-tabs-selectors'>
						{feesTabs.map((tab) => {
							return (
								<Tab className={`fees-tabs-selector fees-tabs-selector-${tab.flatData.className}`} key={tab.id}>
									<h6> {tab.flatData.tabName} </h6>
								</Tab>
							)
						})}
					</div>
				</TabList>

				{feesTabs.map((tab) => {
					return (
						<TabPanel key={tab.id}>
							<div className='fees-tabs-content'>
								<h2> {tab.flatData.tabHeading} </h2>
								<p>{tab.flatData.tabText}</p>
								<ul>
									{tab.flatData.tabListItems.map((item) => {
										return (
											<li key={item.id}>
												<span> {item.flatData.leftText} </span>
												<span> {item.flatData.rightText} </span>
											</li>
										)
									})}
								</ul>
							</div>
							{tab.flatData.tabName === 'For people' && <FeesLimits />}
							{tab.flatData.stuckComponent === 'StuckBusiness' ? <StuckBusiness /> : <StuckPeople />}
						</TabPanel>
					)
				})}
			</Tabs>
		</>
	)
}

export default FeesTabs
