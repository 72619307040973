import { useEffect, useContext, Fragment, Suspense, lazy } from "react"
import { useParams } from 'react-router-dom';

// Context
import AppContext from "../../Context/App/appContext"
import AppNavbar from "../Modules/AppNavbar"

// Modules
import Spinner from "../Modules/Spinner"
import Theme from "../Modules/Theme"

// Lazy Modules
const BusinessRewardsShowcase = lazy(() => import("../Modules/BusinessRewardsShowcase"))
const MMASummary = lazy(() => import("../Modules/MMASummary"))
const HowMMABusiness = lazy(() => import("../Modules/HowMMABusiness"))
const BusinessTabs = lazy(() => import("../Modules/BusinessTabs"))
const BusinessSliders = lazy(() => import("../Modules/BusinessSliders"))
const StuckBusiness = lazy(() => import("../Modules/StuckBusiness"))
const Footer = lazy(() => import("../Modules/Footer"))

const ForBusinessRewardsAndIncentives = () => {
  const appContext = useContext(AppContext)
  const { business, getBusiness } = appContext
  const { tab } = useParams();

  useEffect(() => {
    window.scrollTo(0, 0)
    getBusiness()
    // eslint-disable-next-line
  }, [])

  return (
    <Fragment>
      <Suspense fallback={<Spinner />}>
        {business ? (
          <Theme id="for-business" className="for-business">
            <AppNavbar />
            <BusinessRewardsShowcase />
            <MMASummary />
            <HowMMABusiness />
            <BusinessTabs initialTab={tab} />
            <BusinessSliders />
            <StuckBusiness />
            <Footer />
          </Theme>
        ) : (
          <Spinner />
        )}
      </Suspense>
    </Fragment>
  )
}

export default ForBusinessRewardsAndIncentives