// ===============================================================
// ---------------------------------------------------------------
// Dependencies
import { useEffect, useContext, Fragment, Suspense, lazy } from "react"

// Context
import AppContext from "../../Context/App/appContext"
import AppNavbar from "../Modules/AppNavbar"

// Modules
import Spinner from "../Modules/Spinner"
import Theme from "../Modules/Theme"

// Lazy Modules
const DealsShowcase = lazy(() => import("../Modules/DealsShowcase"))
const DealsInfo = lazy(() => import("../Modules/DealsInfo"))
const Footer = lazy(() => import("../Modules/Footer"))


// ===============================================================
const Deals = () => {
	// ---------------------------------------------------------------
	// Context
	const appContext = useContext(AppContext)
	const { getPeople, people,getFaqs, getAllFaqs } = appContext
	// ---------------------------------------------------------------
	// Get content and scroll to top on load
	useEffect(() => {
		getPeople()
		window.scrollTo(0, 0);
		getFaqs()
		getAllFaqs()
		// eslint-disable-next-line
	}, [])



	return (
		<Fragment>
			<Suspense fallback={<Spinner />}>
				{people ? (
					<Theme id="link-card" className="link-card">
						<AppNavbar />
						<DealsShowcase />
						<DealsInfo />
						<Footer />
					</Theme>
				) : (
					<Spinner />
				)}
			</Suspense>
		</Fragment>
	)
}

export default Deals
