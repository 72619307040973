import React from "react"
import { useLocation } from "react-router-dom"

const Theme = ({ children, id, className, isAgent }) => {
	const { search } = useLocation()
	const ttl = 3600000
	if (isAgent) {
		localStorage.removeItem("theme")
		return (
			<section id={id} className={`${className} agent`}>
				{children}
			</section>
		)
	}
	if (search && search !== "") {
		if (search.includes("?view=sr")) {
			localStorage.setItem("theme", JSON.stringify({ themeName: search, ttl: Date.now() }))
			return (
				<section id={id} className={`${className} sr`}>
					{children}
				</section>
			)
		} else if (search.includes("?view=ch")) {
			localStorage.setItem("theme", JSON.stringify({ themeName: search, ttl: Date.now() }))
			return (
				<section id={id} className={`${className} ch`}>
					{children}
				</section>
			)
		} else if (search.includes("?view=qq")) {
			localStorage.setItem("theme", JSON.stringify({ themeName: search, ttl: Date.now() }))
			return (
				<section id={id} className={`${className} qq`}>
					{children}
				</section>
			)
		} else {
			return (
				<section id={id} className={`${className}`}>
					{children}
				</section>
			)
		}
	} else if (localStorage.getItem("theme")) {
		const data = JSON.parse(localStorage.getItem("theme"))
		if (data.ttl < Date.now() - ttl) {
			localStorage.removeItem("theme")
		} else if (data.themeName.includes("?view=sr")) {
			return (
				<section id={id} className={`${className} sr`}>
					{children}
				</section>
			)
		} else if (data.themeName.includes("?view=ch")) {
			return (
				<section id={id} className={`${className} ch`}>
					{children}
				</section>
			)
		} else if (data.themeName.includes("?view=qq")) {
			return (
				<section id={id} className={`${className} qq`}>
					{children}
				</section>
			)
		} else {
			return (
				<section id={id} className={`${className}`}>
					{children}
				</section>
			)
		}
	} else {
		return (
			<section id={id} className={`${className}`}>
				{children}
			</section>
		)
	}
}

export default Theme
