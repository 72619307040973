// ===============================================================
// ---------------------------------------------------------------
// Dependencies
import { useEffect, useContext, Fragment, Suspense, lazy } from "react"

// Context
import AppContext from "../../Context/App/appContext"
import HowToDepositMoneyWhatsapp from "../HowToSteps/HowToDepositMoneyWhatsapp"
import AppNavbar from "../Modules/AppNavbar"
import GetStartedSteps from "../Modules/GetStartedSteps"

// Modules
import Spinner from "../Modules/Spinner"
import StuckBusinessWhatsapp from "../Modules/StuckBusinessWhatsapp"
import Theme from "../Modules/Theme"
import WhatsappHeaderIcons from "../Modules/WhatsappHeaderIcons"
import WhatsappShowCase from "../Modules/WhatsappShowCase"
import XtraSavingsInfoWhatsapp from "../Modules/XtraSavingsInfoWhatsapp"

// Lazy Modules
const Footer = lazy(() => import("../Modules/Footer"))

// ===============================================================
const Whatsapp = () => {
	// ---------------------------------------------------------------
	// Context
	const appContext = useContext(AppContext)
	const { business, getBusiness } = appContext

	// ---------------------------------------------------------------
	// Scroll to top on load and fetch content
	useEffect(() => {
		window.scrollTo(0, 0)
		getBusiness()
		// eslint-disable-next-line
	}, [])

	return (
		<Fragment>
			<Suspense fallback={<Spinner />}>
				{business ? (
					<Theme id="for-business" className="for-business">
						<AppNavbar />
						<WhatsappShowCase />
						<WhatsappHeaderIcons />
						<GetStartedSteps/>
						<HowToDepositMoneyWhatsapp reverse={true} bgColor={'#f1f2f6'} />
						<XtraSavingsInfoWhatsapp/>
						<StuckBusinessWhatsapp />
						<Footer />
					</Theme>
				) : (
					<Spinner />
				)}
			</Suspense>
		</Fragment>
	)
}

export default Whatsapp
