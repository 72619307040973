// ===============================================================
// ---------------------------------------------------------------
// Dependencies
import { useEffect, useContext, Fragment, Suspense, lazy } from "react"

// Context
import AppContext from "../../Context/App/appContext"
import AppNavbar from "../Modules/AppNavbar"

// Modules
import Spinner from "../Modules/Spinner"
import Theme from "../Modules/Theme"
import OnboardingKycFica from "../Modules/OnboardingKycFica"
import OnboardingFAQ from "../Modules/OnboardingFAQ"


// Lazy Modules
const OnboardingShowcase = lazy(() => import("../Modules/OnboardingShowcase"))

const Footer = lazy(() => import("../Modules/Footer"))

// ===============================================================
const Onboarding = () => {
	// ---------------------------------------------------------------
	// Context
	const appContext = useContext(AppContext)
	const { business, getBusiness } = appContext

	// ---------------------------------------------------------------
	// Scroll to top on load and fetch content
	useEffect(() => {
		window.scrollTo(0, 0)
		getBusiness()
		// eslint-disable-next-line
	}, [])

	return (
		<Fragment>
			<Suspense fallback={<Spinner />}>
				{business ? (
					<Theme id="for-business" className="for-business">
						<AppNavbar />
						<OnboardingShowcase />
                        <OnboardingKycFica />
						<OnboardingFAQ/>
						<Footer />
					</Theme>
				) : (
					<Spinner />
				)}
			</Suspense>
		</Fragment>
	)
}

export default Onboarding
