// ===============================================================
// ---------------------------------------------------------------
// Dependencies
import { useContext } from "react"

// Context
import AppContext from "../../Context/App/appContext"
import Button from "./Button"

// ===============================================================
const XtraSavingsInfoWhatsapp = () => {
	// ---------------------------------------------------------------
	// Context
	const appContext = useContext(AppContext)
	const { image_url } = appContext

	// ---------------------------------------------------------------
	// Destructured variables

	const registerButton = {
		id: "1",
		flatData: {
			isExternalLink: false,
			buttonText: "Register & Link",
			buttonLink: "/register",
			buttonBgColor: [
				{
					flatData: {
						colorHex: "#5f2f65",
					},
				},
			],
			buttonTextColor: null,
			buttonBorderColor: null,
		},
	}

	const signInButton = {
		id: "1",
		flatData: {
			isExternalLink: false,
			buttonText: "Login & Link",
			buttonLink: "/login",
			buttonBgColor: [
				{
					flatData: {
						colorHex: "#0a9ba9",
					},
				},
			],
			buttonTextColor: null,
			buttonBorderColor: null,
		},
	}

	return (
		<div className="xtra-savings-info" id="xtra-savings-info">
			<div className="xtra-savings-info-container">
				<div className="xtra-savings-info-image">
					<img src={`${image_url}9b20c01f-3536-4a7c-b1e0-775c0bff0561`} alt="" />
				</div>
				<div className="deals-info-content">
					<h2>Your Xtra Savings card has even more power!</h2>
					<p style={{marginBottom : "20px"}}>
					If you’re a card and not a phone person, you can use your Xtra Savings card to deposit and withdraw cash and pay for groceries in-store.
					</p>
					<div className="xtra-savings-info-buttons">
						<Button button={registerButton} />
						<Button button={signInButton} />
					</div>
				</div>
			</div>
		</div>
	)
}

export default XtraSavingsInfoWhatsapp
