// ===============================================================
// ---------------------------------------------------------------
// Define action types for Context API
export const WINDOW_RESIZE = "WINDOW_RESIZE"
export const WINDOW_SCROLL = "WINDOW_SCROLL"
export const SET_LAST_SCROLL = "SET_LAST_SCROLL"
export const GET_NAVBAR = "GET_NAVBAR"
export const GET_FOOTER = "GET_FOOTER"
export const GET_ABOUT = "GET_ABOUT"
export const GET_BUSINESS = "GET_BUSINESS"
export const GET_PEOPLE = "GET_PEOPLE"
export const GET_FAQS = "GET_FAQS"
export const GET_ALL_FAQS = "GET_ALL_FAQS"
export const SET_CURRENT_FAQ = "SET_CURRENT_FAQ"
export const GET_FEES = "GET_FEES"
export const GET_FOUROHFOUR = "GET_FOUROHFOUR"
export const GET_STUCK_BUSINESS = "GET_STUCK_BUSINESS"
export const GET_STUCK_PEOPLE = "GET_STUCK_PEOPLE"
export const SET_HOW_TAB_INDEX = "SET_HOW_TAB_INDEX"
export const SET_CURRENT_STEP = "SET_CURRENT_STEP"
export const SET_ACCOUNT_TYPE = "SET_ACCOUNT_TYPE"
export const SET_MOBILE_NUMBER = "SET_MOBILE_NUMBER"
export const SET_ID_NUMBER = "SET_ID_NUMBER"
export const SET_PASSPORT_NUMBER = "SET_PASSPORT_NUMBER"
export const SET_SELECTED_ID_TYPE = "SET_SELECTED_ID_TYPE"
export const SET_FIRST_NAME = "SET_FIRST_NAME"
export const SET_LAST_NAME = "SET_LAST_NAME"
export const SET_EMAIL_ADDRESS = "SET_EMAIL_ADDRESS"
export const SET_PIN = "SET_PIN"
export const SET_CONFIRM_PIN = "SET_CONFIRM_PIN"
export const SET_ACCEPT_DATA_PRIVACY = "SET_ACCEPT_DATA_PRIVACY"
export const SET_READ_TCS = "SET_READ_TCS"
export const SET_SEND_UPDATES = "SET_SEND_UPDATES"
export const SET_SMS_UPDATES = "SET_SMS_UPDATES"
export const SET_WHATSAPP_UPDATES = "SET_WHATSAPP_UPDATES"
export const SET_EMAIL_UPDATES = "SET_EMAIL_UPDATES"
export const SET_CARD_TYPE = "SET_CARD_TYPE"
export const SET_CARD_NUMBER = "SET_CARD_NUMBER"
export const SET_OTP = "SET_OTP"
export const SET_PROMO_CODE = "SET_PROMO_CODE"
export const SET_SECURITY_QUESTION_1 = "SET_SECURITY_QUESTION_1"
export const SET_SECURITY_QUESTION_2 = "SET_SECURITY_QUESTION_2"
export const SET_SECURITY_QUESTION_3 = "SET_SECURITY_QUESTION_3"
export const SET_SECURITY_CORRECT_ANSWER_1 = "SET_SECURITY_CORRECT_ANSWER_1"
export const SET_SECURITY_CORRECT_ANSWER_2 = "SET_SECURITY_CORRECT_ANSWER_2"
export const SET_SECURITY_CORRECT_ANSWER_3 = "SET_SECURITY_CORRECT_ANSWER_3"
export const SET_SECURITY_SELECTED_ANSWER_1 = "SET_SECURITY_SELECTED_ANSWER_1"
export const SET_SECURITY_SELECTED_ANSWER_2 = "SET_SECURITY_SELECTED_ANSWER_2"
export const SET_SECURITY_SELECTED_ANSWER_3 = "SET_SECURITY_SELECTED_ANSWER_3"
export const SET_SECURITY_ANSWER_1_1 = "SET_SECURITY_ANSWER_1_1"
export const SET_SECURITY_ANSWER_1_2 = "SET_SECURITY_ANSWER_1_2"
export const SET_SECURITY_ANSWER_1_3 = "SET_SECURITY_ANSWER_1_3"
export const SET_SECURITY_ANSWER_2_1 = "SET_SECURITY_ANSWER_2_1"
export const SET_SECURITY_ANSWER_2_2 = "SET_SECURITY_ANSWER_2_2"
export const SET_SECURITY_ANSWER_2_3 = "SET_SECURITY_ANSWER_2_3"
export const SET_SECURITY_ANSWER_3_1 = "SET_SECURITY_ANSWER_3_1"
export const SET_SECURITY_ANSWER_3_2 = "SET_SECURITY_ANSWER_3_2"
export const SET_SECURITY_ANSWER_3_3 = "SET_SECURITY_ANSWER_3_3"
export const SET_RECAP = "SET_RECAP"
export const SET_USER_ID = "SET_USER_ID"
export const SET_ERROR_MOBILE_NUMBER = "SET_ERROR_MOBILE_NUMBER"
export const SET_ERROR_ID_NUMBER = "SET_ERROR_ID_NUMBER"
export const SET_ERROR_PASSPORT_NUMBER = "SET_ERROR_PASSPORT_NUMBER"
export const SET_ERROR_EMAIL_ADDRESS = "SET_ERROR_EMAIL_ADDRESS"
export const SET_ERROR_PIN = "SET_ERROR_PIN"
export const SET_ERROR_FIRST_NAME = "SET_ERROR_FIRST_NAME"
export const SET_ERROR_LAST_NAME = "SET_ERROR_LAST_NAME"
export const SET_ERROR_CONFIRM_PIN = "SET_ERROR_CONFIRM_PIN"
export const SET_ERROR_OTP = "SET_ERROR_OTP"
export const SET_ERROR_LINK_CARD = "SET_ERROR_LINK_CARD"
export const SET_ERROR_CARD_NUMBER = "SET_ERROR_CARD_NUMBER"
export const SET_TOKEN = "SET_TOKEN"
export const SET_HAS_CARDS = "SET_HAS_CARDS"
export const SET_CARD_OTP = "SET_CARD_OTP"
export const SET_CARD_OTP_IS_SENT = "SET_CARD_OTP_IS_SENT"
export const SET_DOB = "SET_DOB"
export const SET_GENDER = "SET_GENDER"
export const SET_LOGIN_ERROR = "SET_LOGIN_ERROR"
export const SET_AUTO_GEN_PROMO_CODE = "SET_AUTO_GEN_PROMO_CODE"
