const PeopleQuery = `
{
  queryForPeoplePageContents {
    id
    flatData {
      peopleShowcase {
        id
        flatData {
          showcaseText
          leftTextFirstRow
          leftTextSecondRow
          leftTextThirdRow
          showcaseRightImage {
            id
            metadata
          }
          showcaseColor {
            flatData {
              colorHex
            }
          }
          showcaseButtons {
            id
            flatData {
              isExternalLink
              buttonText
              buttonLink
              buttonBgColor {
                flatData {
                  colorHex
                }
              }
              buttonTextColor {
                flatData {
                  colorHex
                }
              }
              buttonBorderColor {
                flatData {
                  colorHex
                }
              }
            }
          }
        }
      }
      peopleCycleHeading
      peopleCycleText
      peopleCycleMainImage {
        id
        metadata
      }
      peopleCycleCircle {
        id
        metadata
      }
      peopleCycleIcons {
        id
        flatData {
          iconHeading
          iconText
          iconImage {
            id
            metadata
          }
        }
      }
      peopleCycleButton {
        id
        flatData {
          isExternalLink
          buttonText
          buttonLink
          buttonBgColor {
            flatData {
              colorHex
            }
          }
          buttonTextColor {
            flatData {
              colorHex
            }
          }
          buttonBorderColor {
            flatData {
              colorHex
            }
          }
        }
      }
      appTilesHeading
      appTilesText
      appTilesCards {
        id
        flatData {
          frontText
          backHeading
          backText
          frontImage {
            id
            metadata
          }
        }
      }
      appTilesBgColor {
        flatData {
          colorHex
        }
      }
      demoHeading
      demoImage {
        id
        metadata
      }
      demoText
      demoSubtext
      peopleStepsHeading
      peopleStepsIcons {
        id
        flatData {
          iconText
          iconImage {
            id
            metadata
          }
        }
      }
      peopleStepsBgColor {
        flatData {
          colorHex
        }
      }
      testimonialsHeading
      testimonyBoxes {
        id
        flatData {
          testimonyName
          testimonyCity
          testimonyText
          testimonyImage {
            id
            metadata
          }
        }
      }
      testimonialsBgColor {
        flatData {
          colorHex
        }
      }
    }
  }
}
`

export default PeopleQuery
