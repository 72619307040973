// ===============================================================
// ---------------------------------------------------------------
// Import types
import {
	SET_CURRENT_STEP,
	SET_ACCOUNT_TYPE,
	SET_MOBILE_NUMBER,
	SET_ID_NUMBER,
	SET_PASSPORT_NUMBER,
	SET_SELECTED_ID_TYPE,
	SET_FIRST_NAME,
	SET_LAST_NAME,
	SET_EMAIL_ADDRESS,
	SET_PIN,
	SET_CONFIRM_PIN,
	SET_ACCEPT_DATA_PRIVACY,
	SET_READ_TCS,
	SET_SEND_UPDATES,
	SET_SMS_UPDATES,
	SET_WHATSAPP_UPDATES,
	SET_EMAIL_UPDATES,
	SET_OTP,
	SET_CARD_TYPE,
	SET_CARD_NUMBER,
	SET_SECURITY_QUESTION_1,
	SET_SECURITY_QUESTION_2,
	SET_SECURITY_QUESTION_3,
	SET_SECURITY_CORRECT_ANSWER_1,
	SET_SECURITY_CORRECT_ANSWER_2,
	SET_SECURITY_CORRECT_ANSWER_3,
	SET_SECURITY_SELECTED_ANSWER_1,
	SET_SECURITY_SELECTED_ANSWER_2,
	SET_SECURITY_SELECTED_ANSWER_3,
	SET_SECURITY_ANSWER_1_1,
	SET_SECURITY_ANSWER_1_2,
	SET_SECURITY_ANSWER_1_3,
	SET_SECURITY_ANSWER_2_1,
	SET_SECURITY_ANSWER_2_2,
	SET_SECURITY_ANSWER_2_3,
	SET_SECURITY_ANSWER_3_1,
	SET_SECURITY_ANSWER_3_2,
	SET_SECURITY_ANSWER_3_3,
	SET_RECAP,
	SET_USER_ID,
	SET_ERROR_MOBILE_NUMBER,
	SET_ERROR_ID_NUMBER,
	SET_ERROR_PASSPORT_NUMBER,
	SET_ERROR_EMAIL_ADDRESS,
	SET_ERROR_PIN,
	SET_ERROR_CONFIRM_PIN,
	SET_ERROR_OTP,
	SET_ERROR_CARD_NUMBER,
	SET_TOKEN,
	SET_ERROR_FIRST_NAME,
	SET_ERROR_LAST_NAME,
	SET_HAS_CARDS,
	SET_CARD_OTP,
	SET_CARD_OTP_IS_SENT,
	SET_DOB,
	SET_GENDER,
	SET_LOGIN_ERROR,
	SET_PROMO_CODE,
	SET_AUTO_GEN_PROMO_CODE,
} from "../types"

// ===============================================================
// ---------------------------------------------------------------
// Define action to take for each case
// eslint-disable-next-line
export default (state, action) => {
	switch (action.type) {
		case SET_CURRENT_STEP:
			return { ...state, currentStep: action.payload }

		case SET_ACCOUNT_TYPE:
			return { ...state, accountType: action.payload }

		case SET_MOBILE_NUMBER:
			return { ...state, mobileNumber: action.payload }

		case SET_ID_NUMBER:
			return { ...state, idNumber: action.payload }

		case SET_PASSPORT_NUMBER:
			return { ...state, passportNumber: action.payload }

		case SET_SELECTED_ID_TYPE:
			return { ...state, selectedIdType: action.payload }

		case SET_FIRST_NAME:
			return { ...state, firstName: action.payload }

		case SET_LAST_NAME:
			return { ...state, lastName: action.payload }

		case SET_EMAIL_ADDRESS:
			return { ...state, emailAddress: action.payload }

		case SET_PIN:
			return { ...state, pin: action.payload }

		case SET_CONFIRM_PIN:
			return { ...state, confirmPin: action.payload }

		case SET_ACCEPT_DATA_PRIVACY:
			return { ...state, acceptDataPrivacy: action.payload }

		case SET_READ_TCS:
			return { ...state, readTCs: action.payload }

		case SET_SEND_UPDATES:
			return { ...state, sendUpdates: action.payload }

		case SET_SMS_UPDATES:
			return { ...state, smsUpdates: action.payload }

		case SET_WHATSAPP_UPDATES:
			return { ...state, whatsappUpdates: action.payload }

		case SET_EMAIL_UPDATES:
			return { ...state, emailUpdates: action.payload }

		case SET_CARD_TYPE:
			return { ...state, cardType: action.payload }

		case SET_CARD_NUMBER:
			return { ...state, cardNumber: action.payload }

		case SET_OTP:
			return { ...state, otp: action.payload }

		case SET_PROMO_CODE:
			return { ...state, promoCode: action.payload }

		case SET_SECURITY_QUESTION_1:
			return { ...state, securityQuestion1: action.payload }

		case SET_SECURITY_QUESTION_2:
			return { ...state, securityQuestion2: action.payload }

		case SET_SECURITY_QUESTION_3:
			return { ...state, securityQuestion3: action.payload }

		case SET_SECURITY_CORRECT_ANSWER_1:
			return { ...state, securityCorrectAnswer1: action.payload }

		case SET_SECURITY_CORRECT_ANSWER_2:
			return { ...state, securityCorrectAnswer2: action.payload }

		case SET_SECURITY_CORRECT_ANSWER_3:
			return { ...state, securityCorrectAnswer3: action.payload }

		case SET_SECURITY_SELECTED_ANSWER_1:
			return { ...state, securitySelectedAnswer1: action.payload }

		case SET_SECURITY_SELECTED_ANSWER_2:
			return { ...state, securitySelectedAnswer2: action.payload }

		case SET_SECURITY_SELECTED_ANSWER_3:
			return { ...state, securitySelectedAnswer3: action.payload }

		case SET_SECURITY_ANSWER_1_1:
			return { ...state, securityAnswer1_1: { text: action.payload.txt, id: action.payload.id } }

		case SET_SECURITY_ANSWER_1_2:
			return { ...state, securityAnswer1_2: { text: action.payload.txt, id: action.payload.id } }

		case SET_SECURITY_ANSWER_1_3:
			return { ...state, securityAnswer1_3: { text: action.payload.txt, id: action.payload.id } }

		case SET_SECURITY_ANSWER_2_1:
			return { ...state, securityAnswer2_1: { text: action.payload.txt, id: action.payload.id } }

		case SET_SECURITY_ANSWER_2_2:
			return { ...state, securityAnswer2_2: { text: action.payload.txt, id: action.payload.id } }

		case SET_SECURITY_ANSWER_2_3:
			return { ...state, securityAnswer2_3: { text: action.payload.txt, id: action.payload.id } }

		case SET_SECURITY_ANSWER_3_1:
			return { ...state, securityAnswer3_1: { text: action.payload.txt, id: action.payload.id } }

		case SET_SECURITY_ANSWER_3_2:
			return { ...state, securityAnswer3_2: { text: action.payload.txt, id: action.payload.id } }

		case SET_SECURITY_ANSWER_3_3:
			return { ...state, securityAnswer3_3: { text: action.payload.txt, id: action.payload.id } }

		case SET_RECAP:
			return { ...state, recap: action.payload }

		case SET_USER_ID:
			return { ...state, userId: action.payload }

		case SET_ERROR_MOBILE_NUMBER:
			return { ...state, errorMobileNumber: action.payload }

		case SET_ERROR_ID_NUMBER:
			return { ...state, errorIdNumber: action.payload }

		case SET_ERROR_PASSPORT_NUMBER:
			return { ...state, errorPassportNumber: action.payload }

		case SET_ERROR_EMAIL_ADDRESS:
			return { ...state, errorEmailAddress: action.payload }

		case SET_ERROR_FIRST_NAME:
			return { ...state, errorFirstName: action.payload }

		case SET_ERROR_LAST_NAME:
			return { ...state, errorLastName: action.payload }

		case SET_ERROR_PIN:
			return { ...state, errorPin: action.payload }

		case SET_ERROR_CONFIRM_PIN:
			return { ...state, errorConfirmPin: action.payload }

		case SET_ERROR_OTP:
			return { ...state, errorOtp: action.payload }

		case SET_ERROR_CARD_NUMBER:
			return { ...state, errorCardNumber: action.payload }

		case SET_TOKEN:
			return { ...state, token: action.payload }

		case SET_HAS_CARDS:
			return { ...state, hasCards: action.payload }

		case SET_CARD_OTP:
			return { ...state, cardOtp: action.payload }

		case SET_CARD_OTP_IS_SENT:
			return { ...state, cardOtpIsSent: action.payload }

		case SET_AUTO_GEN_PROMO_CODE:
			return { ...state, autoGenPromoCode: action.payload }

		case SET_DOB:
			const dobFormat = new Date(
				`${action.payload.split("").slice(0, 2).join("")} ${action.payload
					.split("")
					.slice(2, 4)
					.join("")} ${action.payload.split("").slice(4, 6).join("")}`
			)
			const y = dobFormat.getFullYear()
			const m = dobFormat.getMonth() + 1
			const d = dobFormat.getDate()
			return { ...state, dob: `${d}-${m}-${y}` }

		case SET_GENDER:
			if (action.payload.split("").slice(6, 10).join("") < 5000) {
				return { ...state, gender: "f" }
			} else {
				return { ...state, gender: "m" }
			}

		case SET_LOGIN_ERROR:
			return { ...state, loginError: action.payload /*  */ }

		default:
			return state
	}
}
