// ===============================================================
// ---------------------------------------------------------------
// Dependencies
import { useContext, useEffect, Fragment } from "react"
import { Link } from "react-router-dom"

// Context
import AppContext from "../../Context/App/appContext"

// ===============================================================
const Footer = ({ isAgent }) => {
	// ---------------------------------------------------------------
	// Context
	const appContext = useContext(AppContext)
	const { image_url, getFooter, footer } = appContext

	// ---------------------------------------------------------------
	// Fetch content
	useEffect(() => {
		getFooter()
		//eslint-disable-next-line
	}, [])

	// ---------------------------------------------------------------
	// Destructured Variables
	const {
		footerColDownloadHeading: downloadHeading,
		footerColDownloadItems: downloadItems,
		footerColCompanyHeading: companyHeading,
		footerColCompanyItems: companyItems,
		footerColShopriteHeading: shopriteHeading,
		footerColShopriteItems: shopriteItems,
		footerColCheckersHeading: checkersHeading,
		footerColCheckersItems: checkersItems,
		footerColOtherHeading: otherHeading,
		footerColOtherItems: otherItems,
		footerBrands,
		footerPolicyLinks,
		footerCopyright,
	} = footer

	return (
		<Fragment>
			{footer && (
				<div className={`footer ${isAgent ? "agent" : ""}`} id="footer">
					<div className="footer-container">
						<div className="footer-grid">
							{!isAgent && (
								<>
									<div className="footer-grid-links">
										<div className="footer-grid-download">
											<h6> {downloadHeading} </h6>
											{downloadItems.map((item) => {
												return (
													<a href={item.flatData.link} target="_blank" rel="noreferrer" key={item.id}>
														<img
															src={`${image_url}${item.flatData.image[0].id}`}
															alt={item.flatData.image[0].metadata.alt}
														/>
													</a>
												)
											})}
										</div>

										<div className="footer-grid-company">
											<h6> {companyHeading} </h6>
											{companyItems.map((item) => {
												return item.flatData.isExternal ? (
													<a href={item.flatData.link} target="_blank" rel="noreferrer" key={item.id}>
														{item.flatData.text}
													</a>
												) : (
													<Link to={item.flatData.link} key={item.id}>
														{item.flatData.text}
													</Link>
												)
											})}
										</div>

										<div className="footer-grid-shoprite">
											<h6> {shopriteHeading} </h6>
											{shopriteItems.map((item) => {
												return (
													<a
														className="contact-a"
														href={item.flatData.link}
														target="_blank"
														rel="noreferrer"
														key={item.id}
													>
														<img
															src={`${image_url}${item.flatData.image[0].id}`}
															alt={item.flatData.image[0].metadata.alt}
														/>
														{item.flatData.text}
													</a>
												)
											})}
										</div>

										<div className="footer-grid-checkers">
											<h6> {checkersHeading} </h6>
											{checkersItems.map((item) => {
												return (
													<a
														className="contact-a"
														href={item.flatData.link}
														target="_blank"
														rel="noreferrer"
														key={item.id}
													>
														<img
															src={`${image_url}${item.flatData.image[0].id}`}
															alt={item.flatData.image[0].metadata.alt}
														/>
														{item.flatData.text}
													</a>
												)
											})}
										</div>

										<div className="footer-grid-other">
											<h6> {otherHeading} </h6>
											{otherItems.map((item) => {
												return item.flatData.isExternal ? (
													<a href={item.flatData.link} target="_blank" rel="noreferrer" key={item.id}>
														{item.flatData.text}
													</a>
												) : (
													<Link to={item.flatData.link} key={item.id}>
														{item.flatData.text}
													</Link>
												)
											})}
										</div>
									</div>
									<div className="footer-grid-logos">
										{footerBrands.map((brand) => {
											return (
												<a href={brand.flatData.brandLink} target="_blank" rel="noreferrer" key={brand.id}>
													<img
														src={`${image_url}${brand.flatData.brandImage[0].id}`}
														alt={brand.flatData.brandImage[0].metadata.alt}
													/>
												</a>
											)
										})}
									</div>
								</>
							)}
							<div className="footer-grid-internals">
								{footerPolicyLinks.map((item) => {
									return (
										<a href={item.flatData.link} target="_blank" rel="noreferrer" key={item.id}>
											{item.flatData.text}
										</a>
									)
								})}
							</div>
							<div className="footer-grid-copy">
								<p> {footerCopyright} </p>
							</div>
						</div>
					</div>
				</div>
			)}
		</Fragment>
	)
}

export default Footer
