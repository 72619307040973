// ===============================================================
// ---------------------------------------------------------------
// Dependencies
import { useEffect, useContext, Fragment, Suspense, lazy } from "react"

// Context
import AppContext from "../../Context/App/appContext"
import AppNavbar from "../Modules/AppNavbar"

// Modules
import Spinner from "../Modules/Spinner"
import Theme from "../Modules/Theme"

// Lazy Modules
const WhereRetailer = lazy(() => import("../Modules/WhereRetailer"))
// const LaunchOffer = lazy(() => import("../Modules/LaunchOffer"))
const StuckPeople = lazy(() => import("../Modules/StuckPeople"))
const Footer = lazy(() => import("../Modules/Footer"))

// ===============================================================
const WhereToUse = () => {
	// ---------------------------------------------------------------
	// Context
	const appContext = useContext(AppContext)
	const { getPeople, people } = appContext

	// ---------------------------------------------------------------
	// Get content and scroll to top on load
	useEffect(() => {
		getPeople()
		window.scrollTo(0, 0)
		// eslint-disable-next-line
	}, [])

	return (
		<Fragment>
			<Suspense fallback={<Spinner />}>
				{people ? (
					<Theme id="where-to-use" className="where-to-use">
						<AppNavbar />
						<WhereRetailer />
						{/* <LaunchOffer /> */}
						<StuckPeople />
						<Footer />
					</Theme>
				) : (
					<Spinner />
				)}
			</Suspense>
		</Fragment>
	)
}

export default WhereToUse
