// ===============================================================
// ---------------------------------------------------------------
// Import types
import {
	WINDOW_RESIZE,
	WINDOW_SCROLL,
	SET_LAST_SCROLL,
	GET_NAVBAR,
	GET_FOOTER,
	GET_ABOUT,
	GET_BUSINESS,
	GET_PEOPLE,
	GET_FAQS,
	GET_ALL_FAQS,
	SET_CURRENT_FAQ,
	GET_FEES,
	GET_FOUROHFOUR,
	GET_STUCK_BUSINESS,
	GET_STUCK_PEOPLE,
	SET_HOW_TAB_INDEX,
} from '../types'

// ===============================================================
// ---------------------------------------------------------------
// Define action to take for each case
// eslint-disable-next-line
export default (state, action) => {
	switch (action.type) {
		case WINDOW_RESIZE:
			return {
				...state,
				windowDimensions: action.payload,
			}

		case WINDOW_SCROLL:
			return {
				...state,
				currentScroll: action.payload,
			}

		case SET_LAST_SCROLL:
			return {
				...state,
				lastScroll: action.payload,
			}

		case GET_NAVBAR:
			return {
				...state,
				navbar: action.payload[0].flatData,
			}

		case GET_FOOTER:
			return {
				...state,
				footer: action.payload[0].flatData,
			}

		case GET_ABOUT:
			return {
				...state,
				about: action.payload[0].flatData,
			}

		case GET_BUSINESS:
			return {
				...state,
				business: action.payload[0].flatData,
			}

		case GET_PEOPLE:
			return {
				...state,
				people: action.payload[0].flatData,
			}

		case GET_ALL_FAQS:
			return {
				...state,
				allFaqs: action.payload,
			}

		case GET_FAQS:
			const determineFaq = (payloadArr, category) => {
				let returnArr = []
				payloadArr.forEach((payload) => {
					if (payload.flatData.faqCategoryName === `faq-${category}`) {
						returnArr = payload.flatData.faqCategoryContents
					}
				})
				return returnArr.length === 0 ? false : returnArr
			}

			return {
				...state,
				faqs: action.payload,
				recipientsFaqs: determineFaq(action.payload, 'recipients'),
				businessFaqs: determineFaq(action.payload, 'business'),
			}

		case SET_CURRENT_FAQ:
			return {
				...state,
				currentFaqQuestion: action.payload.faqQuestion,
				currentFaqAnswer: action.payload.faqAnswer,
			}

		case GET_FEES:
			return {
				...state,
				fees: action.payload[0].flatData,
			}

		case GET_FOUROHFOUR:
			return {
				...state,
				fourOhFour: action.payload[0].flatData,
			}

		case GET_STUCK_BUSINESS:
			return {
				...state,
				stuckBusiness: action.payload[0].flatData,
			}

		case GET_STUCK_PEOPLE:
			return {
				...state,
				stuckPeople: action.payload[0].flatData,
			}

		case SET_HOW_TAB_INDEX:
			return {
				...state,
				howTabIndex: action.payload,
			}

		default:
			return state
	}
}
