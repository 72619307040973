// ===============================================================
// ---------------------------------------------------------------
// Dependencies
import { useContext, Fragment } from 'react'

// Context
import AppContext from '../../Context/App/appContext'

// ===============================================================
const WhatsappHeaderIcons = () => {
	// ---------------------------------------------------------------
	// Context


	// ---------------------------------------------------------------
	// Destructured Variables

	function scrollToInfo() {
		if (window) {
			const anchorComment = document.getElementById("howTomain");
			if (anchorComment) {
				let y = anchorComment.scrollHeight
				anchorComment.scrollIntoView({behavior: "smooth"});

			}
		}
	}

	return (
		<Fragment>

			<div className='whatsapp-basics' id='mma-basics'>
				<h2>Pay over 800 Pay@ bills and accounts
					via the Checkers WhatsApp channel!
				</h2>
				<div className='whatsapp-basics-features'>
					<div className='whatsapp-basics-feature'>
						<div className='img-container whatsapp-Basic-Image'>
							<img style={{ minWidth: '156px', maxWidth: '156px' }}
								src='https://cms.computicket.com/api/assets/mma/047e4302-9454-478d-ab60-a6cd16fd58b2/wi1.png?version=0'
								alt='icon'
							/>
						</div>
						<div className='whatsapp-Basic-Header'>
							<h3>Safe and secure</h3>
						</div>
						<div className='whatsapp-Basic-Body'>
							<p>Powered by Checkers,<br />
								a division of  the Shoprite<br />
								Group of Companies.<br />
								Your account is PIN-protected..</p>
						</div>
						<div className='whatsapp-Basic-Link'>
							<a href='/about/account-security' target='_blank'><u>Tell me more</u></a>
						</div>

					</div>
					<div className='whatsapp-basics-feature'>
						<div className='img-container whatsapp-Basic-Image'>
							<img style={{ minWidth: '156px', maxWidth: '156px' }}
								src='https://cms.computicket.com/api/assets/mma/d4859a7c-0388-4e4d-8948-dfa6458e5dcd/wi2.png?version=0'
								alt='icon'
							/>
						</div>
						<div className='whatsapp-Basic-Header'>
							<h3>No fees</h3>
						</div>
						<div className='whatsapp-Basic-Body'>
							<p>No monthly fees<br />
								or transaction fees<br />
								for bill payments.</p>
						</div>
						<div className='whatsapp-Basic-Link'>
							<a href='/account-fees-and-limits' target='_blank'><u>See fees</u></a>
						</div>

					</div>
					<div className='whatsapp-basics-feature'>
						<div className='img-container whatsapp-Basic-Image'>
							<img style={{ minWidth: '156px', maxWidth: '156px' }}
								src='https://cms.computicket.com/api/assets/mma/a6ca31b2-7076-425a-8e64-083aa3bf37f8/wi3.png?version=0'
								alt='icon'
							/>
						</div>
						<div className='whatsapp-Basic-Header'>
							<h3>Instant payment</h3>
						</div>
						<div className='whatsapp-Basic-Body'>
							<p>Paying via WhatsApp<br />
								is quick and easy.</p>
						</div>
						<div className='whatsapp-Basic-Link'>
							<a onClick={() => scrollToInfo()}><u>See how</u></a>
						</div>

					</div>

				</div>
			</div>
		</Fragment>
	)
}

export default WhatsappHeaderIcons
