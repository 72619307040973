const StuckPeopleQuery = `
{
   queryStuckPeopleContents {
     id
     flatData {
       leftColumnHeading
       leftColumnText
       leftColumnImage {
         id
         metadata
       }
       leftColumnButton {
         id
         flatData {
           isExternalLink
           buttonText
           buttonLink
           buttonBgColor {
             flatData {
               colorHex
             }
           }
           buttonTextColor {
             flatData {
               colorHex
             }
           }
           buttonBgColor {
             flatData {
               colorHex
             }
           }
         }
       }
       rightColumnHeading
       rightColumnText
       rightColumnEmail
       rightColumnPhone
       rightColumnImage {
         id
         metadata
       }
       phoneIcon {
         id
         metadata
       }
       emailIcon {
         id
         metadata
       }
     }
   }
 }
 
 `
export default StuckPeopleQuery
