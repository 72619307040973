const BusinessQuery = `
{
  queryForBusinessPageContents {
    id
    flatData {
      businessShowcase {
        id
        flatData {
          showcaseText
          leftTextFirstRow
          leftTextSecondRow
          leftTextThirdRow
          showcaseRightImage {
            id
            metadata
          }
          showcaseButtons {
            id
            flatData {
              isExternalLink
              buttonText
              buttonLink
              buttonBgColor {
                flatData {
                  colorHex
                }
              }
              buttonTextColor {
                flatData {
                  colorHex
                }
              }
              buttonBorderColor {
                flatData {
                  colorHex
                }
              }
            }
          }
          showcaseColor {
            id 
            flatData {
              colorHex
            }
          }
        }
      }
      mmaSummaryHeading
      mmaSummaryParagraph
      mmaSummaryImage {
        id
        metadata
      }
      howMmaHeading
      howMmaParagraph
      howMmaIcons {
        id
        flatData {
          iconText
          iconImage {
            id
            metadata
          }
        }
      }
      howMmaSelectOptions {
        id
        flatData {
          value
          label
        }
      }
      businessTabsHeading
      businessTabsPanels {
        id
        flatData {
          tabName
          classAdd
          panelHeading
          panelText
          panelListItems {
            id
            flatData {
              listItemText
            }
          }
          panelButtons {
            id
            flatData {
              isExternalLink
              buttonText
              buttonLink
              buttonBgColor {
                id
                flatData {
                  colorHex
                }
              }
              buttonTextColor {
                id
                flatData {
                  colorHex
                }
              }
              buttonBorderColor {
                id
                flatData {
                  colorHex
                }
              }
            }
          }
          panelImage {
            id
            metadata
          }
          panelBgColor {
            id
            flatData {
              colorHex
            }
          }
        }
      }
      businessTabsBgColor {
        id
        flatData {
          colorHex
        }
      }
      businessSliderHeading
      businessSliderParagraph
      businessSliderBadgeText
      businessSliderSubbadgeText
      businessSliderOptions {
        id
        flatData {
          sliderNumber
          sliderAmountRange
          sliderPercentImage {
            id
            metadata
          }
        }
      }
      businessSliderFootnote
      businessStepsHeading
      businessStepsListItems {
        id
        flatData {
          listItemHeading
          listItemText
          listItemPhone
          listItemEmail
          listItemImage {
            id
            metadata
          }
        }
      }
      businessStepsBgColor {
        id
        flatData {
          colorHex
        }
      }
      clientsHeading
      clientsStats {
        id
        flatData {
          statHeading
          statText
        }
      }
      clientsImages {
        id
        metadata
      }
      clientsBgColor {
        id
        flatData {
          colorHex
        }
      }
    }
  }
}

 `

export default BusinessQuery
