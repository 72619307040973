// Dependencies
import { useEffect, Fragment, Suspense, lazy } from "react"
import AppNavbar from "../Modules/AppNavbar"
// import InviteFriends from "../Modules/inviteFriends"
// import PromoWidget from "../Modules/PromoWidget"
// import ShareUpsellSingle from "../Modules/ShareUpsellSingle"

// Context
// import AppContext from '../../Context/App/appContext'

// Modules
import Spinner from "../Modules/Spinner"
import Theme from "../Modules/Theme"

// Lazy Modules
const Footer = lazy(() => import("../Modules/Footer"))

const SiteDisabled = () => {
	// Context
	// const appContext = useContext(AppContext)
	// const { getFaqs, getAllFaqs, faqs } = appContext

	// Scroll to top on load and get content
	useEffect(() => {
		window.scrollTo(0, 0)
		// eslint-disable-next-line
	}, [])

	return (
		<Fragment>
			<Suspense fallback={<Spinner />}>
				<Theme id="share" className="share">
					<AppNavbar />
					<div className="register-widget" style={{ backgroundImage: 'none' }} id="promo-widget">

						<div className="register-widget-container" style={{ marginTop: '160px' }}>
							<h2 style={{ lineHeight: '40px',fontSize : '45px' }}> Our heart is broken</h2>
							<p style={{color : '#57606F' ,fontSize : '25px', marginTop : '10px'}}>Our systems are currently overloaded.<br />
								Please don’t give up on us.<br />
								<b>We’ll be back online soon.</b>
							</p>
							<div style={{textAlign : 'center' , marginTop : '20px'}}>

							<img  style={{width : '300px'}}src="https://cms.computicket.com/api/assets/mma/3884c76c-843e-418e-900e-6bb169d5c90d/sitedown.png?version=0"></img>
							</div>

						</div>
					</div>

					<Footer />
				</Theme>
			</Suspense>
		</Fragment>
	)
}

export default SiteDisabled
