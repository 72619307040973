// ===============================================================
// ---------------------------------------------------------------
// Dependencies
import { useReducer, useEffect } from 'react'

// Context API
import AppContext from './appContext'
import AppReducer from './appReducer'
import {
	WINDOW_RESIZE,
	WINDOW_SCROLL,
	SET_LAST_SCROLL,
	GET_NAVBAR,
	GET_FOOTER,
	GET_ABOUT,
	GET_BUSINESS,
	GET_PEOPLE,
	GET_FAQS,
	GET_ALL_FAQS,
	SET_CURRENT_FAQ,
	GET_FEES,
	GET_FOUROHFOUR,
	GET_STUCK_BUSINESS,
	GET_STUCK_PEOPLE,
	SET_HOW_TAB_INDEX,
} from '../types'

// Queries
import NavbarQuery from '../../Components/Queries/NavbarQuery'
import FooterQuery from '../../Components/Queries/FooterQuery'
import AboutQuery from '../../Components/Queries/AboutQuery'
import BusinessQuery from '../../Components/Queries/BusinessQuery'
import PeopleQuery from '../../Components/Queries/PeopleQuery'
import FaqQuery from '../../Components/Queries/FaqQuery'
import AllFaqQuery from '../../Components/Queries/AllFaqQuery'
import FeesQuery from '../../Components/Queries/FeesQuery'
import FourOhFourQuery from '../../Components/Queries/FourOhFourQuery'
import StuckBusinessQuery from '../../Components/Queries/StuckBusinessQuery'
import StuckPeopleQuery from '../../Components/Queries/StuckPeopleQuery'

// ===============================================================
// ---------------------------------------------------------------
// Declare intial state for windowDimensions state variable
const getWindowDimensions = () => {
	const { innerWidth: width } = window
	return width
}

// ---------------------------------------------------------------
// Initialize all global state
const AppState = (props) => {
	const initialState = {
		navbar: false,
		footer: false,
		about: false,
		business: false,
		people: false,
		faqs: [],
		recipientsFaqs: [],
		businessFaqs: [],
		fees: false,
		fourOhFour: false,
		stuckBusiness: false,
		stuckPeople: false,
		currentFaqQuestion: '',
		currentFaqAnswer: '',
		image_url: 'https://cms.computicket.com/api/assets/mma/',
		api_url: 'https://cms.computicket.com/api/content/mma/graphql',
		allFaqs: [],
		windowDimensions: getWindowDimensions(),
		currentScroll: window.pageYOffset,
		lastScroll: 0,
		howTabIndex: 0,
	}
	const [state, dispatch] = useReducer(AppReducer, initialState)

	// ===============================================================
	// Define global functions
	// ---------------------------------------------------------------
	// Window Resize Event and Handler
	useEffect(() => {
		const handleResize = () => {
			dispatch({
				type: WINDOW_RESIZE,
				payload: getWindowDimensions(),
			})
		}

		window.addEventListener('resize', handleResize)
	}, [])

	// ---------------------------------------------------------------
	// Window Scroll Event and Handler
	useEffect(() => {
		const handleScroll = () => {
			dispatch({
				type: WINDOW_SCROLL,
				payload: window.pageYOffset,
			})
		}

		window.addEventListener('scroll', handleScroll)

		// eslint-disable-next-line
	}, [])

	useEffect(() => {
		dispatch({
			type: SET_LAST_SCROLL,
			payload: state.currentScroll,
		})
	}, [state.currentScroll])

	// ---------------------------------------------------------------
	// Set current FAQ
	const setCurrentFaq = (faq) => {
		dispatch({
			type: SET_CURRENT_FAQ,
			payload: faq,
		})
	}

	// ---------------------------------------------------------------
	// Cell Split
	const cellSplit = (number) => {
		const displayCellArr = number.split('').splice(3)
		displayCellArr.unshift('0')

		const firstThree = displayCellArr.splice(0, 3).join('')
		const middleThree = displayCellArr.splice(0, 3).join('')
		const lastFour = displayCellArr.splice(0, 4).join('')

		const displayCell = `${firstThree} ${middleThree} ${lastFour}`
		return displayCell
	}

	// ===============================================================
	// Fetch requests for global content
	// ---------------------------------------------------------------
	// Get Navbar Content
	const getNavbar = async () => {
		const res = await fetch(state.api_url, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				query: NavbarQuery,
			}),
		})
		const data = await res.json()
		const flatData = data.data.queryNavbarContents

		dispatch({
			type: GET_NAVBAR,
			payload: flatData,
		})
	}

	// ---------------------------------------------------------------
	// Get Footer Content
	const getFooter = async () => {
		const res = await fetch(state.api_url, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				query: FooterQuery,
			}),
		})
		const data = await res.json()
		const flatData = data.data.queryFooterContents

		dispatch({
			type: GET_FOOTER,
			payload: flatData,
		})
	}

	// ---------------------------------------------------------------
	// Get About Content
	const getAbout = async () => {
		const res = await fetch(state.api_url, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				query: AboutQuery,
			}),
		})
		const data = await res.json()
		const flatData = data.data.queryHomePageContents

		dispatch({
			type: GET_ABOUT,
			payload: flatData,
		})
	}

	// ---------------------------------------------------------------
	// Get Business Content
	const getBusiness = async () => {
		const res = await fetch(state.api_url, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				query: BusinessQuery,
			}),
		})
		const data = await res.json()
		const flatData = data.data.queryForBusinessPageContents

		dispatch({
			type: GET_BUSINESS,
			payload: flatData,
		})
	}

	// ---------------------------------------------------------------
	// Get People Content
	const getPeople = async () => {
		const res = await fetch(state.api_url, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				query: PeopleQuery,
			}),
		})
		const data = await res.json()
		const flatData = data.data.queryForPeoplePageContents

		dispatch({
			type: GET_PEOPLE,
			payload: flatData,
		})
	}

	// ---------------------------------------------------------------
	// Get Faqs
	const getFaqs = async () => {
		const res = await fetch(state.api_url, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				query: FaqQuery,
			}),
		})
		const data = await res.json()
		const flatData = data.data.queryFaqCategoriesContents

		dispatch({
			type: GET_FAQS,
			payload: flatData,
		})
	}

	// ---------------------------------------------------------------
	// Get All Faqs
	const getAllFaqs = async () => {
		const res = await fetch(state.api_url, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				query: AllFaqQuery,
			}),
		})
		const data = await res.json()
		const allFaqsArr = []
		data.data.queryFaqContents.map((faq) => {
			allFaqsArr.push({
				value: {
					faqAnswer: faq.flatData.faqAnswer,
					faqQuestion: faq.flatData.faqQuestion,
					faqId: faq.id,
				},
			})
			return true
		})

		dispatch({
			type: GET_ALL_FAQS,
			payload: allFaqsArr,
		})
	}

	// ---------------------------------------------------------------
	// Get Fees Content
	const getFees = async () => {
		const res = await fetch(state.api_url, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				query: FeesQuery,
			}),
		})
		const data = await res.json()
		const flatData = data.data.queryFeesContents

		dispatch({
			type: GET_FEES,
			payload: flatData,
		})
	}

	// ---------------------------------------------------------------
	// Get 404 Content
	const getFourOhFour = async () => {
		const res = await fetch(state.api_url, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				query: FourOhFourQuery,
			}),
		})
		const data = await res.json()
		const flatData = data.data.query404PageContents

		dispatch({
			type: GET_FOUROHFOUR,
			payload: flatData,
		})
	}

	// ---------------------------------------------------------------
	// Get Stuck Business Content
	const getStuckBusiness = async () => {
		const res = await fetch(state.api_url, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				query: StuckBusinessQuery,
			}),
		})
		const data = await res.json()
		const flatData = data.data.queryStuckBusinessContents

		dispatch({
			type: GET_STUCK_BUSINESS,
			payload: flatData,
		})
	}

	// ---------------------------------------------------------------
	// Get Stuck People Content
	const getStuckPeople = async () => {
		const res = await fetch(state.api_url, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				query: StuckPeopleQuery,
			}),
		})
		const data = await res.json()
		const flatData = data.data.queryStuckPeopleContents

		dispatch({
			type: GET_STUCK_PEOPLE,
			payload: flatData,
		})
	}

	// ---------------------------------------------------------------
	// Set Tab index for How To Use Page
	const setHowTabIndex = (index) => {
		dispatch({
			type: SET_HOW_TAB_INDEX,
			payload: index,
		})
	}

	// ===============================================================
	// Define state and functions to Context Provider
	return (
		<AppContext.Provider
			value={{
				image_url: state.image_url,
				api_url: state.api_url,
				windowDimensions: state.windowDimensions,
				currentScroll: state.currentScroll,
				lastScroll: state.lastScroll,
				navbar: state.navbar,
				footer: state.footer,
				about: state.about,
				business: state.business,
				people: state.people,
				faqs: state.faqs,
				allFaqs: state.allFaqs,
				businessFaqs: state.businessFaqs,
				recipientsFaqs: state.recipientsFaqs,
				currentFaqQuestion: state.currentFaqQuestion,
				currentFaqAnswer: state.currentFaqAnswer,
				fees: state.fees,
				fourOhFour: state.fourOhFour,
				stuckBusiness: state.stuckBusiness,
				stuckPeople: state.stuckPeople,
				howTabIndex: state.howTabIndex,
				cellSplit,
				getNavbar,
				getFooter,
				getAbout,
				getBusiness,
				getPeople,
				getFaqs,
				getAllFaqs,
				setCurrentFaq,
				getFees,
				getFourOhFour,
				getStuckBusiness,
				getStuckPeople,
				setHowTabIndex,
			}}
		>
			{props.children}
		</AppContext.Provider>
	)
}

export default AppState
